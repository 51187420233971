import { useState } from "react";
import api from "../api";

const useUploader = () => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadedFileUrl, setUploadedFileUrl] = useState('');
  const [uploadError, setUploadError] = useState(null);

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await api.post('main/upload/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          setUploadProgress(progress);
        },
      });

      if (response && response.data.url) {
        setUploadedFileUrl(response.data.url);
      } else {
        setUploadError('Failed to get file URL from the server.');
      }
    } catch (error) {
      setUploadError('Failed to upload the file.');
    }
  };

  return { uploadFile, uploadProgress, uploadedFileUrl, uploadError };
};

export default useUploader;
