/** @format */

import React, { useEffect, useState } from "react";
import InputField from "../../components/utils/InputField";
import { Button } from "@windmill/react-ui";
import { PAYMENT_METHODS } from "../../utils/constants";
import api from "../../api";
import { useNavigate } from "react-router-dom";

const CreateOrders = () => {
	const [orderSources, setOrderSources] = useState([]);
	const [productList, setProductList] = useState([]);

	const navigate = useNavigate();

	const [orderData, setOrderData] = useState({
		invoiceNo: "",
		// orderTime: "",
		customerName: "",
		email: "",
		phone: "",
		deliveryAddress: "",
		billingAddress: "",
		method: "",
		source: "",
	});

	// State to store the list of products
	const [products, setProducts] = useState([
		{
			productName: "",
			quantity: "",
			// unitPrice: "",
			// totalPrice: "",
		},
	]);

	// State to track validation errors
	const [validationErrors, setValidationErrors] = useState({
		invoiceNo: "",
		orderTime: "",
		customerName: "",
		deliveryAddress: "",
		billingAddress: "",
		method: "",
		source: "",
	});

	useEffect(() => {
		const fetchSources = async () => {
			const response = await api.get("/activities/sources-list/");

			if (response?.app_data?.StatusCode === 6000) {
				setOrderSources(response?.app_data?.data || []);
			}
		};
		const fetchProducts = async () => {
			const response = await api.get("/products/viewproduct/");

			if (response?.app_data?.StatusCode === 6000) {
				setProductList(response?.app_data?.data || []);
			}
		};
		fetchSources();
		fetchProducts();
	}, []);

	// Validation function
	const validateField = (name, value) => {
		let error = "";
		switch (name) {
			case "invoiceNo":
				if (!value) error = "Invoice number is required";
				break;
			// case "orderTime":
			// 	if (!value) error = "Order time is required";
			// break;
			case "customerName":
				if (!value) error = "Customer name is required";
				break;
			case "email":
				if (!value) error = "Email is required";
				break;
			case "phone":
				if (!value) error = "Phone is required";
				break;
			case "deliveryAddress":
				if (!value) error = "Delivery address is required";
				break;
			case "billingAddress":
				if (!value) error = "Billing address is required";
				break;
			case "method":
				if (!value) error = "Method is required";
				break;
			// case "productName":
			// 	if (!value) error = "Product name is required";
			// 	break;
			case "source":
				if (!value) error = "Source is required";
				break;
			case "quantity":
				// case "unitPrice":
				// case "totalPrice":
				if (!value) {
					error = `${name.charAt(0).toUpperCase() + name.slice(1)} is required`;
				} else if (isNaN(value) || Number(value) <= 0) {
					error = `${
						name.charAt(0).toUpperCase() + name.slice(1)
					} must be a positive number`;
				}
				break;
			default:
				break;
		}
		return error;
	};

	// Handle changes in the order data fields
	const handleOrderChange = (e) => {
		const { name, value } = e.target;
		console.log({ name, value });

		setOrderData({
			...orderData,
			[name]: value,
		});
	};

	// Handle changes in product details
	const handleProductChange = (index, e) => {
		const { name, value } = e.target;
		const updatedProducts = [...products];
		updatedProducts[index] = {
			...updatedProducts[index],
			[name]: value,
		};
		console.log(updatedProducts);

		setProducts(updatedProducts);

		// Clear validation error if the field is valid
		if (value) {
			setValidationErrors((prevErrors) => ({
				...prevErrors,
				[name]: "",
			}));
		}
	};

	// Add a new product dynamically
	const addProduct = () => {
		setProducts([
			...products,
			{ productName: "", quantity: "", unitPrice: "", totalPrice: "" },
		]);
	};

	// Handle blur (when the cursor moves out of a field)
	const handleBlur = (e) => {
		const { name, value } = e.target;
		const error = validateField(name, value);
		setValidationErrors((prevErrors) => ({
			...prevErrors,
			[name]: error,
		}));
	};

	// Validate all fields before submitting
	const validateAllFields = () => {
		const newValidationErrors = { ...validationErrors };

		Object.keys(orderData).forEach((key) => {
			const error = validateField(key, orderData[key]);
			newValidationErrors[key] = error;
		});

		products.forEach((product, index) => {
			Object.keys(product).forEach((key) => {
				const error = validateField(key, product[key]);
				newValidationErrors[`product_${index}_${key}`] = error;
			});
		});

		setValidationErrors(newValidationErrors);

		return Object.values(newValidationErrors).every((err) => !err);
	};

	// Submit function
	const handleSubmit = async (event) => {
		event.preventDefault();
		if (validateAllFields()) {
			const response = await api.post("/activities/admin-create-orders/", {
				...orderData,
				invoiceNumber: orderData.invoiceNo,
				products,
			});
			if (response?.app_data?.StatusCode === 6000) {
				navigate("/orders");
			}
		} else {
			console.error("Validation errors found:", validationErrors);
		}
	};

	return (
		<form onSubmit={handleSubmit} action="">
			<div>
				<h2 className="text-white text-[30px] font-semibold leading-[36.31px] tracking-[0.03em] text-left">
					Create Orders
				</h2>
				<br />
				<br />
				<div className="bg-[#1F2937] p-5">
					{/* General Section */}
					<div className="w-full pt-[40px] gap-2">
						<h1 className="font-dm-sans text-[20px] font-semibold leading-[26px] text-left text-white">
							General
						</h1>
						<div className="border-[0.5px] border-[#DFDAE080]"></div>
						<br />
						<br />

						<div className="w-[921px] gap-[126px]">
							{/* Invoice No */}
							<div className="py-3 flex gap-10 justify-between">
								<p className="font-dm-sans text-white text-[14px] font-semibold leading-[26px] text-left whitespace-nowrap">
									INVOICE NO
								</p>
								<div className="w-[751px]">
									<InputField
										name="invoiceNo"
										value={orderData.invoiceNo}
										onChange={handleOrderChange}
										onBlur={handleBlur}
										// type="number"
									/>

									{validationErrors.invoiceNo && (
										<p className="text-white text-sm">
											{validationErrors.invoiceNo}
										</p>
									)}
								</div>
							</div>

							{/* Order Time */}
							{/* <div className="py-3 flex gap-10 justify-between">
								<p className="font-dm-sans text-white text-[14px] font-semibold leading-[26px] text-left whitespace-nowrap">
									ORDER TIME
								</p>
								<div className="w-[751px]">
									<InputField
										name="orderTime"
										value={orderData.orderTime}
										onChange={handleOrderChange}
										onFocus={(e) => (e.target.type = "time")} // Change to time when focused
										onBlur={handleBlur}
										type={orderData.orderTime ? "time" : "text"} // Show text when empty, time when there's a value
									/>

									{validationErrors.orderTime && (
										<p className="text-white text-sm">
											{validationErrors.orderTime}
										</p>
									)}
								</div>
							</div> */}

							{/* Customer Name */}
							<div className="py-3 flex gap-10 justify-between">
								<p className="font-dm-sans text-white text-[14px] font-semibold leading-[26px] text-left whitespace-nowrap">
									CUSTOMER NAME
								</p>
								<div className="w-[751px]">
									<InputField
										name="customerName"
										value={orderData.customerName}
										onChange={handleOrderChange}
										onBlur={handleBlur}
									/>
									{validationErrors.customerName && (
										<p className="text-white text-sm">
											{validationErrors.customerName}
										</p>
									)}
								</div>
							</div>
							<div className="py-3 flex gap-10 justify-between">
								<p className="font-dm-sans text-white text-[14px] font-semibold leading-[26px] text-left whitespace-nowrap">
									Email
								</p>
								<div className="w-[751px]">
									<InputField
										name="email"
										value={orderData.email}
										onChange={handleOrderChange}
										onBlur={handleBlur}
										// type="number"
									/>

									{validationErrors.invoiceNo && (
										<p className="text-white text-sm">
											{validationErrors.invoiceNo}
										</p>
									)}
								</div>
							</div>
							<div className="py-3 flex gap-10 justify-between">
								<p className="font-dm-sans text-white text-[14px] font-semibold leading-[26px] text-left whitespace-nowrap">
									Phone
								</p>
								<div className="w-[751px]">
									<InputField
										name="phone"
										value={orderData.phone}
										onChange={handleOrderChange}
										onBlur={handleBlur}
										// type="number"
									/>

									{validationErrors.invoiceNo && (
										<p className="text-white text-sm">
											{validationErrors.invoiceNo}
										</p>
									)}
								</div>
							</div>

							{/* Delivery Address */}
							<div className="py-3 flex gap-10 justify-between">
								<p className="font-dm-sans text-white text-[14px] font-semibold leading-[26px] text-left whitespace-nowrap">
									DELIVERY ADDRESS
								</p>
								<div className="w-[751px]">
									<InputField
										name="deliveryAddress"
										value={orderData.deliveryAddress}
										onChange={handleOrderChange}
										onBlur={handleBlur}
									/>
									{validationErrors.deliveryAddress && (
										<p className="text-white text-sm">
											{validationErrors.deliveryAddress}
										</p>
									)}
								</div>
							</div>

							{/* Billing Address */}
							<div className="py-3 flex gap-10 justify-between">
								<p className="font-dm-sans text-white text-[14px] font-semibold leading-[26px] text-left whitespace-nowrap">
									BILLING ADDRESS
								</p>
								<div className="w-[751px]">
									<InputField
										name="billingAddress"
										value={orderData.billingAddress}
										onChange={handleOrderChange}
										onBlur={handleBlur}
									/>
									{validationErrors.billingAddress && (
										<p className="text-white text-sm">
											{validationErrors.billingAddress}
										</p>
									)}
								</div>
							</div>

							{/* Method */}
							<div className="py-3 flex gap-10 justify-between">
								<p className="font-dm-sans text-white text-[14px] font-semibold leading-[26px] text-left whitespace-nowrap">
									METHOD
								</p>
								<div className="w-[751px]">
									<select
										name="method"
										value={orderData.method}
										onChange={handleOrderChange}
										onBlur={handleBlur}
										className="block text-white w-full h-12 border px-3 py-1 text-sm focus:outline-none leading-5 rounded-md bg-gray-700 focus:bg-gray-700 border-gray-600 focus:border-gray-500 dark:bg-gray-700"
									>
										<option>Choose a method</option>
										{PAYMENT_METHODS?.map((item) => (
											<option value={item.slug}>{item.title}</option>
										))}
									</select>
									{validationErrors.method && (
										<p className="text-white text-sm">
											{validationErrors.method}
										</p>
									)}
								</div>
							</div>

							{/* Source */}
							<div className="py-3 flex gap-10 justify-between">
								<p className="font-dm-sans text-white text-[14px] font-semibold leading-[26px] text-left whitespace-nowrap">
									SOURCE
								</p>
								<div className="w-[751px]">
									<select
										name="source"
										value={orderData.source}
										onChange={handleOrderChange}
										onBlur={handleBlur}
										className="block text-white w-full h-12 border px-3 py-1 text-sm focus:outline-none leading-5 rounded-md bg-gray-700 focus:bg-gray-700 border-gray-600 focus:border-gray-500 dark:bg-gray-700"
									>
										<option>Choose a source</option>
										{orderSources?.map((source) => (
											<option value={source.id}>{source.name}</option>
										))}
									</select>
									{validationErrors.source && (
										<p className="text-white text-sm">
											{validationErrors.source}
										</p>
									)}
								</div>
							</div>
						</div>
					</div>

					{/* Products Section */}
					<div className="w-full pt-[40px] gap-2">
						<h1 className="font-dm-sans text-[20px] font-semibold leading-[26px] text-left text-white">
							PRODUCTS
						</h1>

						{/* Loop through products */}
						{products.map((product, index) => (
							<div key={index}>
								<div className="border-[0.5px] border-[#DFDAE080]"></div>
								<br />
								<br />
								<div className="w-[921px] gap-[126px]">
									<div className="py-3 flex gap-10 justify-between">
										<p className="font-dm-sans text-white text-[14px] font-semibold leading-[26px] text-left whitespace-nowrap">
											PRODUCT NAME
										</p>
										<div className="w-[751px]">
											<select
												name="productName"
												value={products.productName}
												onChange={(e) => handleProductChange(index, e)}
												onBlur={(e) => handleBlur(e)}
												className="block text-white w-full h-12 border px-3 py-1 text-sm focus:outline-none leading-5 rounded-md bg-gray-700 focus:bg-gray-700 border-gray-600 focus:border-gray-500 dark:bg-gray-700"
											>
												<option>Choose a product</option>
												{productList?.map((product) => (
													<option key={product?.id} value={product?.id}>
														{product?.name}
													</option>
												))}
											</select>
											{validationErrors[`product_${index}_productName`] && (
												<p className="text-white text-sm">
													{validationErrors[`product_${index}_productName`]}
												</p>
											)}
										</div>
									</div>

									{/* Quantity */}
									<div className="py-3 flex gap-10 justify-between">
										<p className="font-dm-sans text-white text-[14px] font-semibold leading-[26px] text-left whitespace-nowrap">
											QUANTITY
										</p>
										<div className="w-[751px]">
											<InputField
												name="quantity"
												value={product.quantity}
												onChange={(e) => handleProductChange(index, e)}
												onBlur={(e) => handleBlur(e)}
												type="number"
											/>
											{validationErrors[`product_${index}_quantity`] && (
												<p className="text-white text-sm">
													{validationErrors[`product_${index}_quantity`]}
												</p>
											)}
										</div>
									</div>

									{/* Unit Price */}
									{/* <div className="py-3 flex gap-10 justify-between">
										<p className="font-dm-sans text-white text-[14px] font-semibold leading-[26px] text-left whitespace-nowrap">
											UNIT PRICE
										</p>
										<div className="w-[751px]">
											<InputField
												name="unitPrice"
												value={product.unitPrice}
												onChange={(e) => handleProductChange(index, e)}
												onBlur={(e) => handleBlur(e)}
												type="number"
											/>
											{validationErrors[`product_${index}_unitPrice`] && (
												<p className="text-white text-sm">
													{validationErrors[`product_${index}_unitPrice`]}
												</p>
											)}
										</div>
									</div> */}

									{/* Total Price */}
									{/* <div className="py-3 flex gap-10 justify-between">
										<p className="font-dm-sans text-white text-[14px] font-semibold leading-[26px] text-left whitespace-nowrap">
											TOTAL PRICE
										</p>
										<div className="w-[751px]">
											<InputField
												name="totalPrice"
												value={product.totalPrice}
												onChange={(e) => handleProductChange(index, e)}
												onBlur={(e) => handleBlur(e)}
												type="number"
											/>
											{validationErrors[`product_${index}_totalPrice`] && (
												<p className="text-white text-sm">
													{validationErrors[`product_${index}_totalPrice`]}
												</p>
											)}
										</div>
									</div> */}
								</div>
							</div>
						))}
						<br />
						<br />

						{/* Add Product Button */}
						<div className="flex">
							<button
								type="reset"
								className="w-[921px] h-[67px] rounded-[10px_10px_10px_10px] border-t-[1px] border-r-[1px] border-b-[1px] border-l-[1px] border-white border-dashed p-[22px_34px] gap-[10px] text-white"
								onClick={addProduct}
							>
								ADD PRODUCT
							</button>
						</div>
					</div>
					<br />
					<br />

					<div className="border-[0.5px] border-[#DFDAE080]"></div>
					<br />

					{/* Submit Button */}
					<div className="flex justify-end">
						<Button
							variant="outlined"
							className="w-[161px] h-[67px] p-[22px_34px] gap-2 rounded-[10px_10px_10px_10px] border border-solid border-white bg-[#4B5563] text-white opacity-100 ml-[40px]"
							type="submit"
						>
							Submit
						</Button>
					</div>
				</div>
			</div>
		</form>
	);
};

export default CreateOrders;
