// DatePicker.js
import React, { useState } from 'react';
import { format } from 'date-fns';

const DatePicker = ({ selectedDate, onDateChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleDateChange = (date) => {
    setIsOpen(false);
    onDateChange(date);
  };

  const toggleDatePicker = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative inline-block text-left w-full">
      <div>
        <span className="rounded-md shadow-sm">
          <button
            type="button"
            onClick={toggleDatePicker}
            className="text-white inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-gray-700 text-sm font-medium text-gray-700 hover:bg-gray-800 focus:outline-none focus:ring focus:border-blue-300"
          >
            {selectedDate ? format(selectedDate, 'MMMM d, yyyy') : 'Select a date'}
          </button>
        </span>
      </div>

      {isOpen && (
        <div className="origin-top-right absolute right-0 mt-2 w-full rounded-md shadow-lg bg-gray-700 ring-1 ring-black ring-opacity-5">
          {/* Calendar component (You can replace this with your preferred date picker library) */}
          <div className="p-4 w-full">
            {/* Include your date picker logic here */}
            {/* This can be a custom-built calendar or use a library like react-datepicker */}
            {/* For simplicity, I'm using a simple input field for manual date entry */}
            <label htmlFor="datepicker" className="block text-sm font-medium text-white">
              Choose a date:
            </label>
            <input
              type="date"
              id="datepicker"
              value={selectedDate ? format(selectedDate, 'yyyy-MM-dd') : ''}
              onChange={(e) => {
                const newDate = e.target.value ? new Date(e.target.value) : null;
                handleDateChange(newDate);
              }}
              className="mt-1 p-1 border border-gray-300 rounded-md w-full bg-gray-700 text-white"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DatePicker;
