import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const handlePageChange = (pageNumber) => {
    onPageChange(pageNumber);
  };

  const handlePrevClick = () => {
    if (currentPage > 1) {
      handlePageChange(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      handlePageChange(currentPage + 1);
    }
  };

  const generatePageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={`${i === currentPage ? 'active' : ''} w-10 h-10 flex items-center justify-center text-white cursor-pointer`}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </li>
      );
    }
    return pageNumbers;
  };

  return (
    <div className="pagination py-2 flex justify-center">
      <button onClick={handlePrevClick} disabled={currentPage === 1}>
        <FaChevronLeft color="white"/>
      </button>
      <ul className='flex'>{generatePageNumbers()}</ul>
      <button onClick={handleNextClick} disabled={currentPage === totalPages}>
        <FaChevronRight color="white"/>
      </button>
    </div>
  );
};

Pagination.propTypes = {
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Pagination;
