/** @format */

import React from "react";
import useGetApi from "../../hooks/useGetApi";
import { useParams } from "react-router-dom";
import { convertUTCStringToFormattedDate } from "../../utils/functions";
import Table from "../../components/utils/Table";

const OrderSingle = () => {
	const { id } = useParams();
	console.log(id, "_____");
	const { data } = useGetApi(`activities/admin/orders/${id}`);

	//   const order_time = convertUTCStringToFormattedDate(data?.order_data)
	console.log(data, "______");
	const columns = React.useMemo(
		() => [
			{
				Header: "Product name",
				accessor: "id",
				Cell: (row) => {
					return <span>{row.row.product_details?.name}</span>;
				},
			},
			{
				Header: "Unit Price",
				accessor: "id",
				Cell: (row) => {
					return <span>{row.row.product_details?.selling_price}</span>;
				},
			},
			{
				Header: "Image",
				accessor: "id",
				Cell: (row) => {
					return (
						<div className="w-20 h-20 p-2">
							<img
								className="object-cover"
								src={row.row?.product_details?.thumbnail}
								alt="thumbnail"
							/>{" "}
						</div>
					);
				},
			},
			{
				Header: "Quantity",
				accessor: "id",
				Cell: (row) => {
					return <span>{row.row?.quantity}</span>;
				},
			},
			{
				Header: "Total Price",
				accessor: "id",
				Cell: (row) => {
					return <span>{row.row?.price}</span>;
				},
			},
		],
		[]
	);
	return (
		<div>
			<div className="bg-gray-800 p-5 rounded">
				<h4 className="text-gray-300 text-2xl font-bold">Order Details</h4>
				<div className="flex justify-between">
					<div className="py-3">
						<h6 className="text-gray-300">Name : {data?.name}</h6>
						<h6 className="text-gray-300">
							Total amount : {data?.total_amount}
						</h6>
						<h6 className="text-gray-300 flex gap-3">
							address :<span></span>
							{data?.address?.first_name} {data?.address?.last_name}
							<br />
							{data?.address?.phone}
							<br /> {data?.address?.address}
							<br />
							{data?.address?.street},{data?.address?.city},
							{data?.address?.state},{data?.address?.country}
							<br />
							pin:{data?.address?.post_code}
						</h6>
					</div>
					<div className="py-3">
						<h6 className="text-gray-300">
							order date :{" "}
							{data?.order_data &&
								convertUTCStringToFormattedDate(data?.order_data)}
						</h6>
						<h6 className="text-gray-300">status : {data?.status}</h6>
					</div>
				</div>
			</div>
			<div className="bg-gray-800 rounded mt-4">
				<Table data={data?.products} columns={columns} />
			</div>
		</div>
	);
};

export default OrderSingle;
