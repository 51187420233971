import React, { useEffect, useState } from "react";
import InputLabel from "../components/utils/InputLabel";
import InputField from "../components/utils/InputField";
import Dropdown from "../components/utils/Dropdown";
import InputPrice from "../components/utils/InputPrice";
import InputNumber from "../components/utils/InputNumber";
import TagInput from "../components/utils/TagInput";
import ImageUploader from "../components/utils/ImageUploader";
import VariantInput from "../components/utils/VarientInput";
import { Button } from "@windmill/react-ui";
import useProductPost from "../hooks/useProductPost";
import useCategoryView from "../hooks/useCategoryView";
import SubCategorySelector from "../components/utils/SubCategorySelector";
import api from "../api";
import useGetApi from "../hooks/useGetApi";

const AddProductModal = (catId) => {
  const [selected, setSelected] = useState("Product");
  // const [selected, setSelected] = useState("Variant");
  const [price, setPrice] = useState();
  const [quantity, setQuantity] = useState();
  const [referalPrice, setReferalPrice] = useState();
  const [purchasePrice, setPurchasePrice] = useState();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [productSku, setProductSku] = useState();
  const [selectedSub, setSelectedSub] = useState("");
  const [productId, setProductId] = useState(catId.catId);
  const [varientId, setVarientId] = useState("");
  const { category } = useCategoryView();
  const [attributeArray, setAttributeArray] = useState([]);
  const [selectedAttribute, setSelectedAttribute] = useState({});
  const [attributeQuantity, setAttributeQuantity] = useState();
  const [attributes, setAttributes] = useState([]);
  const [varientImages, setImageVarients] = useState();
  console.log(selectedAttribute, "________________________");
  const handleToggle = () => {
    setSelected(selected === "Product" ? "Variant" : "Product");
  };
  const { loading, error, postProduct } = useProductPost();
  const [selectedOption, setSelectedOption] = useState("Select an option");
  const options = ["Option 1", "Option 2", "Option 3"];
  const [refresh, setRefresh] = useState(false);

  const handleSelect = (option) => {
    setSelectedOption(option);
  };
  useEffect(() => {
    if (productId) {
      setSelected("Variant");
    }
  }, [catId, productId]);

  const { data: Product } = useGetApi(
    `products/admin/product-single/${productId}`,
    refresh
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    api
      .post("products/admin/addProduct/", {
        name: name,
        description: description,
        price: price,
        quantity: quantity,
        offers: 0,
        referal_price: referalPrice,
        purchase_price: purchasePrice,
        subcategory_id: selectedSub,
        brand_id: "dc1ebaf1-c9e7-4889-8fcd-626bafaf6bae",
      })
      .then((res) => {
        if (res.app_data.StatusCode === 6000) {
          setSelected("Variant");
          setProductId(res.app_data.data.product.id);
        } else {
          alert(res.app_data.data.message);
        }
        console.log(res.app_data, "_____Data____");
      });
  };

  const handleTagsChanged = (updatedTags) => {
    // Do something with the updated tags, such as logging or using them in the parent component's state
    console.log("Updated Tags:", updatedTags);
  };
  const handleSubCategorySelect = (selectedNode) => {
    console.log(selectedNode.key, "Selected Node");
    setSelectedSub(selectedNode.key);
    // Access selectedNode properties as needed
  };

  useEffect(() => {
    api.get("products/view_attribute/").then((res) => {
      setAttributeArray(res.app_data.data);
    });
  }, []);
  const handleAddAttribute = () => {
    console.log("in hear");
    setAttributes((prev) => [
      ...prev,
      {
        attribute: selectedAttribute.id,
        attribute_name: selectedAttribute.name,
        quantity: attributeQuantity,
      },
    ]);
    setAttributeQuantity("");
    setSelectedAttribute({});
    // }
  };
  const handleRemoveVariant = (index) => {
    const updatedAttribute = [...attributes];
    updatedAttribute.splice(index, 1);
    setAttributes(updatedAttribute);
  };
  console.log(attributes, attributeQuantity);
  const handleUpload = () => {
    api
      .post(`products/admin/add-product-varient/${productId}/`, {
        varients: varientImages,
        attributes: attributes,
      })
      .then((res) => {
        setImageVarients();
        setAttributes();
        setRefresh((prev) => !prev);
        // setVarientId(res.app_data.data.id);
      });
    // Send selectedImages to API or use it as needed
  };
  console.log(productId, "_______product___", Product);
  return (
    <div>
      <div>
        <div className="flex items-center ">
          <button
            onClick={handleToggle}
            className={`px-4 py-2 focus:outline-none ${
              selected === "Product"
                ? "bg-blue-500 text-white"
                : "bg-gray-900 text-gray-700"
            }`}
          >
            Product
          </button>
          <button
            onClick={() => {}}
            className={`px-4 py-2 focus:outline-none ${
              selected === "Variant"
                ? "bg-blue-500 text-white"
                : "bg-gray-900 text-white-700"
            }`}
          >
            Variant
          </button>
        </div>
      </div>
      {selected === "Product" && (
        <div className="py-3" style={{ overflowY: "auto" }}>
          <form onSubmit={handleSubmit}>
            <div className="flex py-3" style={{ width: "500px" }}>
              <InputLabel label="Product Title/Name" />
              <InputField onChange={(e) => setName(e.target.value)} />
            </div>
            <div className="flex py-3" style={{ width: "500px" }}>
              <InputLabel label="Product description" />
              <InputField onChange={(e) => setDescription(e.target.value)} />
            </div>
            <div className="flex py-3" style={{ width: "500px" }}>
              <InputLabel label="Product SKU" />
              <InputField onChange={(e) => setProductSku(e.target.value)} />
            </div>
            <div className="flex py-3" style={{ width: "500px" }}>
              <InputLabel label="Category" />
              <Dropdown
                options={category}
                selectedOption={selectedOption}
                onSelect={handleSelect}
              />
            </div>
            <div className="flex py-3" style={{ width: "500px" }}>
              <InputLabel label="Sub Category" />
              <SubCategorySelector
                catId={selectedOption.id}
                onSelect={handleSubCategorySelect}
              />
            </div>
            <div className="flex py-3" style={{ width: "500px" }}>
              <InputLabel label="Price" />
              <InputPrice value={price} onChange={(e) => setPrice(e)} />
            </div>
            <div className="flex py-3" style={{ width: "500px" }}>
              <InputLabel label="Purchase Price" />
              <InputPrice
                value={purchasePrice}
                onChange={(e) => setPurchasePrice(e)}
              />
            </div>
            <div className="flex py-3" style={{ width: "500px" }}>
              <InputLabel label="Referral Price" />
              <InputPrice
                value={referalPrice}
                onChange={(e) => setReferalPrice(e)}
              />
            </div>
            <div className="flex py-3" style={{ width: "500px" }}>
              <InputLabel label="Quantity" />
              <InputNumber value={quantity} onChange={(e) => setQuantity(e)} />
            </div>
            {/* <div className="flex py-3" style={{width:'500px'}}>
                        <InputLabel label="Tags"/>
                        <TagInput onTagsChanged={handleTagsChanged}/>
                    </div> */}
            <div className="flex justify-end py-4">
              <button
                type="submit"
                className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded"
              >
                Add Product
              </button>
            </div>
          </form>
        </div>
      )}
      {selected === "Variant" && (
        <div className="py-4 w-full">
          <div className="flex gap-3 py-3">
            {Product?.varients?.map((varients) => (
              <div className="bg-white pb-1">
                {varients?.images
                  .filter((image) => image.primary === true)
                  .map((item) => (
                    <div className="w-100">
                      <img src={item.image} className="w-full" />
                    </div>
                  ))}
                <h4 className="text-gray-800">Quantity: {varients.quantity}</h4>
              </div>
            ))}
          </div>
          <div style={{ width: "700px" }}>
            <ImageUploader
              dataId={productId}
              setVarientId={setVarientId}
              varientId={varientId}
              onChange={(e) => setImageVarients(e)}
            />
          </div>
          <div className="py-3" style={{ width: "100%" }}>
            <div className="flex w-full py-3">
              <InputLabel label={"Attribute"} />
              <Dropdown
                options={attributeArray}
                onSelect={(e) => setSelectedAttribute(e)}
                selectedOption={selectedAttribute}
              />
            </div>
            <div className="flex w-full">
              <InputLabel label={"Quantity"} place />
              <InputNumber
                placeholder="Enter Quantity"
                onChange={(e) => setAttributeQuantity(e)}
                value={attributeQuantity}
              />
            </div>
            <div className="flex justify-end py-5">
              <Button
                style={{ backgroundColor: "orange" }}
                onClick={() => handleAddAttribute()}
              >
                Add
              </Button>
            </div>
            <div className="flex gap-3 flex-wrap w-full">
              {attributes?.map((item, index) => (
                <span
                  key={index}
                  className="bg-gray-200 rounded-md text-black px-3 py-1 flex items-center "
                  style={{ width: "150pxs" }}
                >
                  <span>{`${item.attribute_name}: ${item.quantity}`}</span>
                  <button
                    onClick={() => handleRemoveVariant(index)}
                    className="ml-2 focus:outline-none text-gray-600"
                  >
                    &#10005;
                  </button>
                </span>
              ))}
            </div>
            {/* <VariantInput /> */}
          </div>
          <div className="flex justify-end">
            <button
              className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded"
              onClick={() => handleUpload()}
            >
              Add Variant
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddProductModal;
