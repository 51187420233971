/** @format */

import React, { useEffect, useState } from "react";
import InputLabel from "../../components/utils/InputLabel";
import InputField from "../../components/utils/InputField";
import TextAreaInput from "../../components/utils/TextAreaInput";
import InputNumber from "../../components/utils/InputNumber";
import PriceInput from "../../components/utils/InputPrice";
import { Button } from "@windmill/react-ui";
import { MdCloudUpload } from "react-icons/md";
import { IoAddCircleOutline } from "react-icons/io5";
import useUploader from "../../hooks/useUploader";
import Dropdown from "../../components/utils/Dropdown";
import useCategoryView from "../../hooks/useCategoryView";
import SubCategorySelector from "../../components/utils/SubCategorySelector";
import api from "../../api";
import useGetApi from "../../hooks/useGetApi";
import { FaPlus } from "react-icons/fa";
import SuccessModal from "../../drawers/SuccessModal";
import ErrorAlert from "../../drawers/ErrorAlert";
import notifyError from "../../drawers/ErrorAlert";
import DeleteIcon from "@mui/icons-material/Delete";
import { useParams } from "react-router-dom";

const EditProduct = () => {
	const { id } = useParams();
	const { data: product_info } = useGetApi(
		`products/admin/view-products/${id}/`
	);
	const [productInfo, setProductInfo] = useState();
	useEffect(() => {
		if (product_info) {
			console.log(product_info);

			delete product_info?.attribute_type;

			setProductInfo(product_info);
			// if (product_info?.category) {
			// 	setSelectedOption({ name: product_info?.category });
			// }
			if (product_info?.sub_category) {
				setSelectedOption({
					id: product_info?.category_id,
					name: product_info?.category,
					sub_category: product_info?.sub_category,
				});
			}
		}
	}, [product_info]);

	console.log(productInfo, "_________hello");
	const [selectedThumbnail, setSelectedThumbnail] = useState(null);
	const [selectedImages, setSelectedImages] = useState([]);
	const { category } = useCategoryView();
	// const [productName, setProductName] = useState("");
	// const [productDescription, setProductDescription] = useState("");
	// const [brand, setBrand] = useState("");
	// const [subCategory, setSubCategory] = useState("");

	// State for General
	// const [sku, setSku] = useState("");
	// const [returnIn, setReturnIn] = useState(0);
	// const [actualPrice, setActualPrice] = useState(0);
	// const [displayPrice, setDisplayPrice] = useState(0);
	const [selectedAttributeDescription, setSelectedAttributeDescription] =
		useState();
	// const [referalAmount, setReferalAmount] = useState("");
	// State for Shipping
	// const [weight, setWeight] = useState(0);
	// const [length, setLength] = useState(0);
	// const [width, setWidth] = useState(0);
	// const [height, setHeight] = useState(0);

	// State for Attributes
	// const [attributeType, setAttributeType] = useState("");
	// const [attributeValue, setAttributeValue] = useState("");
	const [quantity, setQuantity] = useState();
	const [selectedOption, setSelectedOption] = useState({
		name: "Select an option",
	});
	console.log(id, "___new");
	const handleSelect = (option) => {
		setSelectedOption(option);
	};
	useEffect(() => {
		notifyError("An error occurred!");
	}, []);
	console.log(selectedThumbnail, selectedImages, "_______");

	const { uploadFile, uploadProgress, uploadedFileUrl, uploadError } =
		useUploader();

	const handleThumbnailUpload = async (event) => {
		const file = event.target.files[0];

		if (file) {
			const imageUrl = await uploadFile(file, true);
			if (imageUrl) {
				setSelectedThumbnail(imageUrl);
			}
		}
	};
	console.log(uploadedFileUrl);
	const handleImageUpload = async (event, thumbnail) => {
		console.log("new__test");
		const file = event.target.files[0];
		console.log(file);
		if (product_info?.images?.length === 9) {
			return;
		}
		if (file) {
			try {
				const formData = new FormData();
				formData.append("file", file);

				const response = await api.post("main/upload/", formData, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				});

				if (thumbnail) {
					console.log("Setting Thumbnail:", uploadedFileUrl);
					setSelectedThumbnail(response.url);
					setProductInfo((prev) => ({
						...prev,
						images: [
							...prev.images,
							{ image: response.url, thumbnail: thumbnail },
						],
					}));
					// End of  Selection
				} else {
					console.log("Setting Image:", uploadedFileUrl);
					setProductInfo((prev) => ({
						...prev,
						images: [
							...prev.images,
							{ image: response.url, thumbnail: thumbnail },
						],
					}));
				}
			} catch (error) {
				console.error("Error uploading file:", error);
			}
		}
	};
	console.log(productInfo, "____prod___info");
	const [selectedAttribute, setSelectedAttribute] = useState();
	console.log(selectedAttribute, "selected attri");
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState();
	console.log(selectedAttribute, "___________aler");
	const handleSubmit = () => {
		api.post("products/admin/editProduct/", productInfo).then((res) => {
			if (res.app_data.StatusCode === 6000) {
				setSuccess(true);
				setSelectedImages([]);
				setSelectedThumbnail();
			} else {
				setError(true);
				setErrorMessage(res.app_data.data.errors);
				setTimeout(() => {
					setError(false);
				}, 3000);
			}
		});
	};
	const { data: attributes } = useGetApi("products/view-attribute-type/");
	const [attributeValues, setAttributeValues] = useState();
	console.log(selectedThumbnail, selectedImages, "_________");
	useEffect(() => {
		if (selectedAttribute?.id) {
			api
				.get(`products/view-attribute-description/${selectedAttribute?.id}/`)
				.then((res) => {
					if (res.app_data.StatusCode === 6000) {
						setAttributeValues(res.app_data.data);
					}
				});
		}
	}, [selectedAttribute]);
	const [attributeArray, setAttributeArray] = useState([]);
	const handleAdd = () => {
		if (!selectedAttributeDescription?.id) {
			return;
		}
		setProductInfo((prev) => ({
			...prev,
			attribute: [
				...prev.attribute,
				{
					id: attributeArray.length + 1,
					attributeName: selectedAttributeDescription?.value,
					attributeDescription: selectedAttributeDescription?.id,
					value: selectedAttributeDescription?.value,
					new_description_id: selectedAttributeDescription?.id,
					quantity: quantity,
				},
			],
		}));
		setSelectedAttributeDescription();
		setQuantity();
	};

	const handleQuantity = (e, id) => {
		// Map over the array and update the quantity for the matching id
		const updatedArray = attributeArray.map((item) => {
			if (item.id === id) {
				return { ...item, quantity: e };
			}
			return item;
		});

		// Set the state with the updated array
		setAttributeArray(updatedArray);
	};
	const handleAttDelete = (id) => {
		// const updatedArray = attributeArray.filter((item) => item.id !== id);
		// setAttributeArray(updatedArray);

		setProductInfo((prev) => {
			const updatedAttribute = [
				...prev.attribute.filter((item) => item.id !== id),
			];
			// updatedAttribute[i].quantity = e.target.value;
			return { ...prev, attribute: updatedAttribute };
		});
	};
	const handleImageDelete = (id) => {
		setProductInfo((prev) => ({
			...prev,
			images: prev.images.filter((image) => image.id !== id),
		}));
	};
	const handleThumbDelete = () => {
		setProductInfo((prev) => ({
			...prev,
			thumbnail: null,
		}));
	};

	return (
		<div>
			<div>
				<h3 className="text-white font-semibold " style={{ fontSize: "20px" }}>
					Edit Products
				</h3>
				<h6 className="text-white" style={{ fontSize: "14px" }}>
					Product/edit-product
				</h6>
			</div>
			<div className="bg-gray-800 p-5 mt-4 rounded">
				<div className="flex gap-3 py-4 border-b border-gray-200 border-solid justify-between">
					<div className="flex gap-3">
						<div
							className="border border-gray-600 border-solid h-10 flex justify-center item-center cursor-none rounded-md text-gray-500 px-3"
							style={{ alignItems: "center" }}
						>
							<h4>{productInfo?.name}</h4>
						</div>
						{/* <div
              className="border border-gray-400 border-dashed h-10 flex justify-center item-center rounded-md text-gray-400 cursor-pointer"
              style={{ width: "120px", alignItems: "center" }}
            >
              <h4>+ Add Product</h4>
            </div> */}
					</div>
					{/* <div>
            <button className="bg-gray-600 py-2 px-5 rounded text-white">
              Reset
            </button>
          </div> */}
				</div>
				<div className="pt-4 flex w-full" style={{ height: "65vh" }}>
					<div className="w-8/12 border-r border-gray-400 pr-4 divide-y h-full overflow-y-scroll scrollbar-hidden">
						<div>
							<h4 className="text-white">Product Detail</h4>
							<div className="py-3 flex">
								<InputLabel label="Product Name" width="30%" />
								<InputField
									onChange={(e) =>
										setProductInfo((prev) => ({
											...prev,
											name: e.target.value,
										}))
									}
									value={productInfo?.name}
								/>
							</div>
							<div className="py-3 flex">
								<InputLabel label="Product Description" width="30%" />
								<TextAreaInput
									onChange={(e) =>
										setProductInfo((prev) => ({
											...prev,
											description: e.target.value,
										}))
									}
									value={productInfo?.description}
								/>
							</div>
							<div className="py-3 flex">
								<InputLabel label="Category" width="30%" />
								<Dropdown
									options={category}
									selectedOption={selectedOption}
									onSelect={handleSelect}
								/>
							</div>
							<div className="py-3 flex">
								<InputLabel label="Sub Category" width="30%" />
								<SubCategorySelector
									catId={selectedOption.id}
									onSelect={(e) =>
										setProductInfo((prev) => ({
											...prev,
											sub_category: e.key,
										}))
									}
								/>
							</div>
						</div>
						<div className="pt-4">
							<h4 className="text-white">General</h4>
							<div className="py-3 flex">
								<InputLabel label="SKU" width="30%" />
								<InputField
									onChange={(e) =>
										setProductInfo((prev) => ({
											...prev,
											product_sku: e.target.value,
										}))
									}
									value={productInfo?.product_sku}
								/>
							</div>
							<div className="py-3 flex">
								<InputLabel label="Return in" width="30%" />
								<InputNumber
									onChange={(e) =>
										setProductInfo((prev) => ({ ...prev, return_in: e }))
									}
									value={productInfo?.return_in}
								/>
							</div>
							<div className="py-3 flex">
								<InputLabel label="Actual Price" width="30%" />
								<PriceInput
									onChange={(e) =>
										setProductInfo((prev) => ({ ...prev, actual_price: e }))
									}
									value={productInfo?.actual_price}
								/>
							</div>
							<div className="py-3 flex">
								<InputLabel label="GST in percent" width="30%" />
								<PriceInput
									onChange={(e) =>
										setProductInfo((prev) => ({ ...prev, gst_price: e }))
									}
									value={productInfo?.gst_price}
								/>
							</div>
							<div className="py-3 flex">
								<InputLabel label="Display Price" width="30%" />
								<PriceInput
									onChange={(e) =>
										setProductInfo((prev) => ({ ...prev, selling_price: e }))
									}
									value={productInfo?.selling_price}
								/>
							</div>
							<div className="py-3 flex">
								<InputLabel label="Refferal amount" width="30%" />
								<PriceInput
									onChange={(e) =>
										setProductInfo((prev) => ({ ...prev, referal_Amount: e }))
									}
									value={productInfo?.referal_Amount}
								/>
							</div>
						</div>
						<div className="pt-4">
							<h4 className="text-white">Shipping</h4>
							<div className="py-3 flex">
								<InputLabel label="Weight" width="30%" />
								<InputNumber
									onChange={(e) =>
										setProductInfo((prev) => ({ ...prev, weight: e }))
									}
									value={productInfo?.weight}
								/>
							</div>
							<div className="py-3 flex">
								<InputLabel label="Length (cm)" width="30%" />
								<InputNumber
									onChange={(e) =>
										setProductInfo((prev) => ({ ...prev, length: e }))
									}
									value={productInfo?.length}
								/>
							</div>
							<div className="py-3 flex">
								<InputLabel label="Width (cm)" width="30%" />
								<InputNumber
									onChange={(e) =>
										setProductInfo((prev) => ({ ...prev, width: e }))
									}
									value={productInfo?.width}
								/>
							</div>
							<div className="py-3 flex">
								<InputLabel label="Height (cm)" width="30%" />
								<InputNumber
									onChange={(e) =>
										setProductInfo((prev) => ({ ...prev, height: e }))
									}
									value={productInfo?.height}
								/>
							</div>
						</div>
						<div className="pt-4">
							<h4 className="text-white">Attributes</h4>
							<div className="py-3 flex">
								<InputLabel label="Attribute type" width="30%" />
								{console.log(productInfo)}
								<Dropdown
									options={attributes}
									onSelect={(e) => {
										setSelectedAttribute(e);
									}}
									selectedOption={selectedAttribute}
									// selectedOption={productInfo?.attribute_type}
								/>
							</div>
							<div className="py-3 flex">
								<InputLabel label="Attribute Value" width="30%" />
								<Dropdown
									options={attributeValues}
									onSelect={(e) => setSelectedAttributeDescription(e)}
									selectedOption={selectedAttributeDescription}
								/>
							</div>
							<div className="py-3 flex">
								<InputLabel label="Quantity" width="30%" />
								<InputNumber
									onChange={(e) => setQuantity(e)}
									value={quantity}
								/>
							</div>
							<div className="flex justify-between">
								<button
									className="bg-blue-500 rounded-full p-2"
									onClick={() => handleAdd()}
								>
									<FaPlus color="white" size={20} />
								</button>
							</div>
							<div className="flex gap-5 mt-5 py-3 flex-wrap">
								{/* {attributeArray?.map((item) => (
                    <span className="bg-gray-300 p-2 rounded">
                      {item.attributeName}-{item.quantity}
                    </span>
                  ))} */}
								{productInfo?.attribute?.map((item, i) => (
									<div className="flex w-1/4 justify-between border border-gray-400 p-2 rounded">
										<div>
											<h5 className="text-white">{item.value}</h5>
										</div>
										<div>
											<input
												type="number"
												className="rounded-md bg-gray-700 border border-gray-400 w-20 text-gray-200 px-2"
												value={item.quantity}
												onChange={(e) =>
													setProductInfo((prev) => {
														const updatedAttribute = [...prev.attribute];
														updatedAttribute[i].quantity = e.target.value;
														return { ...prev, attribute: updatedAttribute };
													})
												}
											/>
										</div>
										<div>
											<h4
												className="text-red-500 cursor-pointer"
												onClick={() => handleAttDelete(item.id)}
											>
												<DeleteIcon />
												delete
											</h4>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
					<div className="w-4/12 pl-3">
						<div
							className="border border-gray-600 border-dashed rounded flex justify-center items-center"
							style={{ height: "200px" }}
						>
							<label
								htmlFor="thumbnailInput"
								className="w-full h-full flex flex-col items-center justify-center"
								style={{ height: "200px" }}
							>
								<input
									type="file"
									id="thumbnailInput"
									className="hidden"
									onChange={(e) => handleImageUpload(e, true)}
								/>
								{productInfo?.thumbnail ? (
									<div className="w-[130px] relative">
										<img
											src={productInfo?.thumbnail}
											alt="Uploaded Thumbnail"
											className="w-full h-full object-contain rounded"
										/>
										<span
											onClick={() => handleThumbDelete(productInfo.thumbnail)}
											className="absolute top-[10px] right-[10px] bg-white w-5 h-5 flex items-center justify-center rounded-full cursor-pointer"
										>
											X
										</span>
									</div>
								) : (
									<>
										<MdCloudUpload
											size={70}
											color="white"
											style={{ marginBottom: "8px" }}
										/>
										<h4 className="text-white">Upload Thumbnail</h4>
									</>
								)}
							</label>
							<h4 className="text-gray-500 mt-2">Max 1 Image</h4>
						</div>
						<h4 className="text-white">Max 9 photos</h4>
						<div className="flex gap-3 flex-wrap max-h-[300px] overflow-y-scroll">
							{product_info?.images?.length < 9 && (
								<>
									<div className="mt-2 flex">
										<label
											htmlFor="fileInput"
											className="border border-gray-600 border-dashed rounded flex items-center justify-center"
											style={{ width: "100px", height: "100px" }}
										>
											<input
												type="file"
												id="fileInput"
												className="hidden"
												onChange={(e) => handleImageUpload(e, false)}
											/>
											<IoAddCircleOutline size={50} color="white" />
										</label>
									</div>
								</>
							)}
							{/* Render other selectedImages elements here */}
							{productInfo?.images
								?.filter((item) => item.thumbnail === false)
								.map((image, index) => (
									<div
										key={index}
										className="relative"
										style={{ width: "100px", height: "100%" }}
									>
										<img
											src={image.image}
											alt={`Uploaded Image ${index + 1}`}
											className="w-full h-full object-contain rounded "
										/>
										<span
											onClick={() => {
												handleImageDelete(image.id);
											}}
											className="absolute top-[5px] right-[10px] bg-white w-5 h-5 flex items-center justify-center rounded-full cursor-pointer"
										>
											X
										</span>
									</div>
								))}
						</div>
					</div>
				</div>
			</div>
			<div
				className="bg-gray-800 mt-3 rounded flex justify-end"
				style={{ height: "60px" }}
			>
				<div
					className="justify-end h-full flex item-center py-3 pr-3"
					style={{}}
				>
					<Button
						className="w-100"
						style={{ backgroundColor: "orange" }}
						onClick={() => handleSubmit()}
					>
						Submit
					</Button>
				</div>
			</div>
			<SuccessModal
				isOpen={success}
				message="Successfully Updated Product"
				onClose={() => setSuccess(false)}
			/>
			<ErrorAlert
				isOpen={error}
				onClose={() => setError(false)}
				message={errorMessage}
			/>
		</div>
	);
};

export default EditProduct;
