import { Button } from "@windmill/react-ui";
import React, { useEffect, useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import useGetApi from "../../../hooks/useGetApi";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const generateRandomData = () => {
  return Array.from({ length: 7 }, () => Math.floor(Math.random() * 1000));
};


const LineGraph = () => {
  const chartRef = useRef(null);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Chart.js Line Chart",
      },
    },
  };
  const {data: graphData} = useGetApi('activities/admin/purchase-graph/')
  // console.log(graphData,'_____',graphData.labels,graphData.data);
  const labels =graphData?.labels

  const data = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: graphData?.data,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      // {
      //   label: "Dataset 2",
      //   data: generateRandomData(),
      //   borderColor: "rgb(53, 162, 235)",
      //   backgroundColor: "rgba(53, 162, 235, 0.5)",
      // },
    ],
  };

  useEffect(() => {
    return () => {
      // Cleanup - destroy the chart when the component unmounts
      const chartInstance = chartRef.current?.chartInstance;
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, []);
  console.log(graphData);
  return (
    <div className="w-full bg-gray-800 p-5 rounded">
      <div>
        <h4 className="font-bold text-white" style={{ fontSize: "20px" }}>
          Weekly Sales
        </h4>
        <div
          className="w-full"
          style={{ borderBottom: ".5px solid #ffffff60" }}
        >
          <div>
            <Button style={{ position: "relative" }}>
              <div
                className="w-full absolute bottom-0 bg-orange-500"
                style={{ height: "2px", borderRadius: "10px" }}
              ></div>
              Sales
            </Button>
          </div>
        </div>
        <div className="max-w-screen-md mx-auto mt-8">
          {graphData && <Line options={options} data={data} ref={chartRef} />}
        </div>
      </div>
    </div>
  );
};

export default LineGraph;
