import { useState } from 'react';
import axios from 'axios';
import api from '../api';

const useProductPost = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const postProduct = async (productData) => {
    setLoading(true);
    try {
      const response = await api.post('products/admin/addProduct/', productData);
      setLoading(false);
      return response.data; // You can handle the response data as needed
    } catch (err) {
      setLoading(false);
      setError(err.message || 'Failed to post product data');
      return null;
    }
  };

  return { loading, error, postProduct };
};

export default useProductPost;
