import React from 'react'
import useGetApi from '../../hooks/useGetApi'
import Table from '../../components/utils/Table';

const Enquiries = () => {
    const {data} = useGetApi('marketing/admin/enquiry/')
    const columns = React.useMemo(
        () => [
          {
            Header: "Name",
            accessor: "name"
          },
          {
            Header:"Email",
            accessor:"email"
          },
          {
            Header: "Subject",
            accessor: "subject"
          },
          {
            Header:"Message",
            accessor:"message"
          }
        ])
  return (
    <div>
      <div className='bg-gray-800 py-6 px-3 rounded'>
        <h3 className='text-3xl text-white font-semibold'>Enquiries</h3>
      </div>
      <div className='mt-3'>
        <Table columns={columns} data={data}/>
      </div>
    </div>
  )
}

export default Enquiries
