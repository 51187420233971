/** @format */

import React, { useState } from "react";
import DashboardMainCard from "../../components/utils/cards/DashboardMainCard";
import { GoStack } from "react-icons/go";
import { LuShoppingCart } from "react-icons/lu";
import { IoMdCard } from "react-icons/io";
import OrderCard from "../../components/utils/cards/OrderCard";
import LineGraph from "../../components/utils/Graphs/LineGraph";
import PieGraph from "../../components/utils/Graphs/PieGraph";
import TableHome from "../../components/utils/TableHome";
import { TfiReload } from "react-icons/tfi";
import { FiTruck } from "react-icons/fi";
import { TiTick } from "react-icons/ti";
import useGetApi from "../../hooks/useGetApi";
import Dropdown from "../../components/utils/Dropdown";
import DropdownStatus from "../../components/utils/DropdownStatus";
import api from "../../api";
import Pagination from "../../components/utils/Pagination";
import { PiHourglassLowFill } from "react-icons/pi";
import { MdOutlineHourglassEmpty } from "react-icons/md";
import { GrView } from "react-icons/gr";
import { CiMenuKebab } from "react-icons/ci";
import { ImCancelCircle } from "react-icons/im";
import { FaDownload, FaTruck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
	const { data: DashBoardData } = useGetApi("activities/admin/dashboard-data/");
	const { data: StatusData } = useGetApi("activities/admin/view-status/");
	const { data: productCount } = useGetApi("activities/admin/product-count/");
	

	const [refresh, setRefresh] = useState(false);
	const handleStatus = async (status, purchase) => {
		api
			.post(`activities/admin/add-purchase-log/${purchase}/`, {
				status_id: status.id,
			})
			.then((res) => {
				console.log(res.app_data.StatusCode, "____");
				if (res.app_data.StatusCode === 6000) {
					setRefresh((prev) => !prev);
				}
			});
	};
	console.log(StatusData, "_____statusData____");
	const { data: orderDetails } = useGetApi(
		"activities/admin/view-order-counts/"
	);
	const [showMenuMap, setShowMenuMap] = useState({});
	console.log(showMenuMap);
	const action_data = {
		Pending: [
			{
				option: "Accept Order",
				icon: <TiTick />,
				filter: "Accepted",
			},
			{
				option: "Cancel Order",
				icon: <ImCancelCircle />,
				filter: "Cancelled",
			},
		],
		Return: [
			{
				option: "Download Slip",
				icon: <FaDownload />,
			},
			{
				option: "Ready to ship",
				icon: <FaTruck />,
				filter: "Shipped",
			},
			{
				option: "Cancel Order",
				icon: <ImCancelCircle />,
				filter: "",
			},
		],
		Cancelled: [
			{
				option: "Mark as Completed",
				icon: <TiTick />,
				filter: "Completed",
			},
		],
	};
	const navigate = useNavigate();
	const toggleMenu = (rowId) => {
		console.log(rowId, "________row_id");
		setShowMenuMap((prevState) => ({
			...prevState,
			[rowId]: !prevState[rowId], // Toggle menu state for the clicked row
		}));
	};
	const columns = React.useMemo(
		() => [
			{
				Header: "Invoice No",
				accessor: "invoice_no",
			},
			{
				Header: "Order Time",
				accessor: "created_at",
			},
			{
				Header: "Customer Name",
				accessor: "user",
			},
			{
				Header: "Method",
				accessor: "id",
				Cell: (row) => <td>online</td>,
			},
			{
				Header: "Amount",
				accessor: "total_amount",
			},
			{
				Header: "Status",
				accessor: "id",
				Cell: (row) => {
					// console.log(row.row.status, "____status__row______");
					return (
						<div className="flex items-center justify-center p-2 text-gray-300 bg-blue-500 rounded-md">
							{row?.row?.status}
						</div>
					);
				},
			},
			{
				Header: "Action",
				accessor: "id",
				Cell: (row) => {
					// const [isMenuOpen, setMenuOpen] = useState(false);
					const isMenuOpen =
						row.row.id in showMenuMap ? showMenuMap[row.row.id] : false;
					// const isMenuOpen = showMenuMap[row.row.id]; // Check if menu is open for this row

					console.log(isMenuOpen, "_______status____");
					const handleStatus = (filter) => {
						api
							.post(`activities/admin/add-purchase-log/${row.row.id}/`, {
								status: filter,
							})
							.then((res) => {
								alert("Updated successfully");
								setRefresh((prev) => prev);
							});
					};
					return (
						<div className="flex item-center justify-start gap-2 relative">
							<span
								className="cursor-pointer"
								onClick={() => navigate(`/orders/${row.row.id}`)}
							>
								<GrView />
							</span>
							<div className="relative">
								<span
									className="cursor-pointer"
									onClick={() => toggleMenu(row.row.id)}
									// onClick={() => setMenuOpen((prev) => !prev)}
								>
									<CiMenuKebab /> {/* Menu icon */}
								</span>
								{isMenuOpen && (
									<div
										className="absolute bg-gray-600 p-2 rounded"
										style={{ width: "180px", left: "-127px", zIndex: 10 }}
									>
										{action_data[row.row.status]?.map((item) => (
											<div
												className="py-2 text-center flex items-center justify-center gap-2 cursor-pointer hover:bg-gray-500"
												onClick={() => handleStatus(item.filter)}
											>
												{item.icon}
												{item.option}
											</div>
										))}
									</div>
								)}
							</div>
						</div>
					);
				},
			},
			// {
			//   Header: "Action",
			//   accessor: "action",
			// },
		],
		[showMenuMap]
	);
	// const data = [
	//   { title: "Cash", value: 7268.0 },
	//   { title: "Card", value: 0.0 },
	//   { title: "Credit", value: 0.0 },
	// ];
	const { data, allData, pagination, setPage } = useGetApi(
		"activities/admin/orders/",
		refresh,
		{ filter: "Pending" }
	);
	console.log(data, "______________dat");
	return (
		<div className="">
			<div>
				<h3
					className="text-white text-md"
					style={{ fontSize: "20px", fontWeight: 700 }}
				>
					Dashboard Overview
				</h3>
			</div>
			{/* <div className="grid flex-wrap gap-4 py-3" > */}
			<div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
				<DashboardMainCard
					icon={<GoStack />}
					title="Todays Order"
					color="#0d9488"
					price={23455.0}
					datas={DashBoardData?.today_orders}
				/>
				<DashboardMainCard
					icon={<GoStack />}
					title="Yesterday Orders"
					color="#fb923c"
					price={23455.0}
					datas={DashBoardData?.yesterday_orders}
				/>
				<DashboardMainCard
					icon={<LuShoppingCart />}
					color="#3c82f6"
					title="This Month"
					price={23455.0}
					datas={DashBoardData?.this_month_orders}
				/>
				<DashboardMainCard
					icon={<IoMdCard />}
					title="Last Month"
					color="#0a91b1"
					price={23455.0}
					datas={DashBoardData?.last_month_orders}
				/>
				<DashboardMainCard
					icon={<IoMdCard />}
					title="All-Time Sales"
					color="#079669"
					price={23455.0}
					datas={DashBoardData?.all_time_sales}
				/>
			</div>
			<div className="grid grid-cols-1 gap-4 mt-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4">
				<OrderCard
					icon={<LuShoppingCart />}
					title="Total Order"
					value={orderDetails?.total_purchase_count}
					color="#f97315"
				/>
				<OrderCard
					icon={<TfiReload />}
					title="Orders Pending"
					value={orderDetails?.pending_purchase_count}
					color="#3c82f6"
				/>
				<OrderCard
					icon={<PiHourglassLowFill />}
					title="Low Stock"
					value={productCount?.low_stock || 0}
					color="#15b8a6"
				/>
				<OrderCard
					icon={<MdOutlineHourglassEmpty />}
					title="Out Of Stock"
					value={productCount?.out_of_stock || 0}
					color="#12b981"
				/>
			</div>
			<div>
				<div className="flex justify-between py-4">
					<h4
						className="text-white"
						style={{ fontSize: "22px", fontWeight: 600 }}
					>
						Recent Order
					</h4>
					<h4 className="items-center text-white">
						Pending orders : {allData?.pending_orders}
					</h4>
				</div>
				<div className="overflow-y-scroll sm:max-width-screen">
					<TableHome data={data} columns={columns} />
					{data && data.length > 0 && (
						<Pagination
							totalPages={pagination.total_pages}
							currentPage={pagination.current_page}
							onPageChange={(e) => setPage(e)}
						/>
					)}
				</div>
			</div>
			<div className="grid grid-cols-1 gap-4 mt-5 mt-6 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2">
				<LineGraph />
				<div className="p-5 bg-gray-800 rounded w-fullx">
					<div>
						<h4 className="font-bold text-white" style={{ fontSize: "20px" }}>
							Best Selling Products
						</h4>
					</div>
					<div>
						<h4 className="text-red-400">No data found</h4>
					</div>
				</div>
				{/* <PieGraph/> */}
			</div>
		</div>
	);
};

export default Dashboard;
