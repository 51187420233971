import React, { useState } from "react";
import InputLabel from "../../components/utils/InputLabel";
import { useNavigate, useParams } from "react-router-dom";
import useGetApi from "../../hooks/useGetApi";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { GrView } from "react-icons/gr";
import Table from "../../components/utils/Table";
import { Button } from "@windmill/react-ui";
import Modal from "../../components/utils/Modal";
import AddProductModal from "../../drawers/AddProductModal";
import SideDrawer from "../../components/utils/SideDrawer";

const ProductSingle = () => {
  const { id } = useParams();
  const [modal,setModal]=useState()
  console.log(id, "____________");
  const {
    data: Product,
    error,
    loading,
  } = useGetApi(`products/admin/product-single/${id}`);
  console.log(Product?.varients);
  const navigate = useNavigate();
  const columns = React.useMemo(
    () => [
      {
        Header: "id",
        accessor: "id",
      },
      {
        Header: "Thumbnail",
        accessor: "id",
        Cell: (row) => {
          const thumbnail = row.row.images?.filter((item) => item.primary)[0];
          console.log(thumbnail,"______thumb");
          console.log(row.row, "_______row");
          return (
            <div className="" style={{width:'50px'}}>
              <img className="w-full object-contain" src={thumbnail.image} alt="product"/>
            </div>
          );
        },
      },
      {
        Header: "quantity",
        accessor: "quantity",
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: (row) => {
            console.log(row.row.id,"row_______");
          return (
            <div className="flex item-center justify-center gap-2">
              <span className="cursor-pointer">
                <FaEdit />
              </span>
              <span className="cursor-pointer">
                <MdDelete />
              </span>
              <span className="cursor-pointer" onClick={()=>navigate(`${row.row.id}`)}>
                <GrView />
              </span>
            </div>
          );
        },
      },
    ],
    []
  );
  return (
    <div>
      <div className="bg-gray-800 p-5 rounded flex flex-wrap">
        <div style={{ width: "50%" }} className="py-3 flex">
          <InputLabel label="Name" />
          <h4 className="text-white">{Product?.name}</h4>
        </div>
        <div style={{ width: "50%" }} className="py-3 flex">
          <InputLabel label="Description" />
          <h4 className="text-white">{Product?.description}</h4>
        </div>
        <div style={{ width: "50%" }} className="py-3 flex">
          <InputLabel label="Price" />
          <h4 className="text-white">{Product?.selling_price}</h4>
        </div>
        <div style={{ width: "50%" }} className="py-3 flex">
          <InputLabel label="Purchase price" />
          <h4 className="text-white">{Product?.selling_price}</h4>
        </div>
        <div style={{ width: "50%" }} className="py-3 flex">
          <InputLabel label="Total Quantity" />
          <h4 className="text-white">{Product?.quantity}</h4>
        </div>
        <div>
          <Button style={{backgroundColor:'orange',width:'180px'}} onClick={()=>setModal(true)}>Add Varient</Button>
        </div>
      </div>
      <div className="bg-gray-800 mt-3 p-5 rounded">
        <div className="text-white text-l">
          <Table columns={columns} data={Product?.varients} />
        </div>
      </div>
      <SideDrawer title="Add Varient" isOpen={modal} onClose={()=>setModal(false)}>
        <AddProductModal catId={id}/>
      </SideDrawer>
    </div>
  );
};

export default ProductSingle;
