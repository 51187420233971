import React from "react";
import Table from "../../components/utils/Table";
import TableBanner from "../../components/utils/TableBanner";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { GrView } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import useGetApi from "../../hooks/useGetApi";

const Banners = () => {

  const navigate = useNavigate()
  const columns = React.useMemo(() => [
    {
      Header: "Section",
      accessor: "section",
    },
    {
      Header: "Action",
      accessor: "id",
      Cell: ({ row }) => {
        console.log(row.id);
        return (
          <div className="flex gap-3 justify-end">
            <span
              className="cursor-pointer"
              onClick={() => navigate(`/banners/${row.id}`)}
            >
              <GrView />
            </span>
            <span
              className="cursor-pointer"
              // onClick={() => handleDeleteProduct(row.row.id)}
            >
              <MdDelete />
            </span>
          </div>
        );
      },
    },
  ]);
  const data = [
    {
      id: 1,
      filter: "category=kids",
      section: 1,
    },
    {
      id: 2,
      filter: "category=Men",
      section: 2,
    },
  ];
  const {data:bannerData} = useGetApi('marketing/admin/view-banners/')
  console.log(bannerData);
  return (
    <div className="">
      <div className="bg-gray-800 py-6 px-4 rounded flex justify-between">
        <h3 className="text-white text-3xl font-bold">Banners</h3>
        <button className="bg-gray-400 py-2 px-3 rounded text-white" onClick={()=>navigate('/add-banners')}>+ Add</button>
      </div>
      <div className="bg-gray-800 py-6 px-4 rounded mt-4">
        <TableBanner columns={columns} data={bannerData} />
      </div>
    </div>
  );
};

export default Banners;
