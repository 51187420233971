/** @format */

import React, { useEffect, useState } from "react";
import InputField from "../../components/utils/InputField";
import useGetApi from "../../hooks/useGetApi";
import Table from "../../components/utils/Table";
import Modal from "../../components/utils/Modal";
import InputLabel from "../../components/utils/InputLabel";
import { Button } from "@windmill/react-ui";
import usePostApi from "../../hooks/usePostApi";
import TextEntry from "../../components/utils/TextEntry";
import api from "../../api";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const Attributes = () => {
	const [modal, setModal] = useState(false);
	const [refresh, setRefresh] = useState(false);

	const { data, error, loading } = useGetApi(
		"products/view-attribute-type/",
		refresh
	);
	const { setName, setValue, postData, name, value } = usePostApi();
	const [values, setValues] = useState([]);
	const navigate = useNavigate();
	const [editModal, setEditModal] = useState(false);
	const [modalId, setModalId] = useState("");
	const [editData, setEditData] = useState({});
	useEffect(() => {
		if (modalId) {
			api.get(`products/admin/attribute/${modalId}`).then((res) => {
				console.log(res.app_data.data, "_______new___");
				setEditData(res.app_data.data);
			});
		}
	}, [modalId]);
	// const [modal,isModal]=useState(false)
	console.log(editData);
	const columns = React.useMemo(
		() => [
			{
				Header: "id",
				accessor: "id",
			},
			{
				Header: "Name",
				accessor: "name",
			},
			{
				Header: "Actions",
				accessor: "id",
				Cell: (row) => {
					console.log(row.row.id, "row_______");
					const handleDeleteProduct = () => {
						api
							.delete(`products/admin/deleteAttribute/${row.row.id}/`)
							.then((response) => {
								// alert("Deleted Successfully!");
								setRefresh((prev) => !prev);
							});
					};
					return (
						<div className="flex  gap-2 item-center">
							<span
								className="cursor-pointer"
								onClick={() => {
									setEditModal(true);
									setModalId(row.row.id);
								}}
							>
								<FaEdit />
							</span>
							<span
								className="cursor-pointer"
								onClick={() => handleDeleteProduct(row.row.id)}
							>
								<MdDelete />
							</span>
							{/* <span className="cursor-pointer" onClick={()=>navigate(`/products/${row.row.id}`)}>
                <GrView />
              </span> */}
						</div>
					);
				},
			},
		],
		[]
	);
	console.log(values);
	const handleAddAttribute = () => {
		try {
			api
				.post("products/admin/add-attribute/", {
					name: name,
					values: values,
				})
				.then((res) => {
					setRefresh((prev) => !prev);
					setModal(false);
				});
		} catch (err) {
			alert(err.message);
		}
	};
	const handleEdit = () => {
		try {
			api
				.post(`products/admin/edit-attribute/`, { ...editData })
				.then((res) => {
					setRefresh((prev) => !prev);
					setEditModal(false);
					console.log(res.app_data);
				});
		} catch (error) {
			console.log();
		}
	};
	return (
		<div>
			<div className="py-3">
				<h3 className="text-white" style={{ fontSize: 25, fontWeight: 700 }}>
					Attributes
				</h3>
			</div>
			<div className="flex gap-5 p-4 bg-gray-800 rounded">
				<div className="flex justify-between w-full gap-2">
					{" "}
					<div></div>
					<button
						className="flex inline-flex items-center justify-center w-auto h-12 px-4 py-2 mr-3 text-sm font-medium leading-5 text-gray-400 align-bottom transition-colors duration-150 bg-gray-700 border border-gray-200 rounded-lg cursor-pointer focus:outline-none md:py-1"
						onClick={() => setModal(true)}
					>
						Add Attributes
					</button>
				</div>
			</div>

			<div className="mt-4 bg-gray-800 rounded">
				<Table columns={columns} data={data} />
			</div>
			<Modal
				title="Add Attribute"
				isOpen={modal}
				onClose={() => setModal(false)}
			>
				<div>
					<div className="flex">
						<InputLabel label={"Name"} />
						<InputField
							onChange={(e) => setName(e.target.value)}
							value={name}
						/>
					</div>
					<div className="mt-4 ">
						<InputLabel label={"Value"} />
						<TextEntry
							onRemoveEntry={(e) =>
								setValues((prev) => prev.filter((val, index) => index != e))
							}
							onAddEntry={(e) => setValues((prev) => [...prev, e])}
							entries={values}
						/>
					</div>
					<div className="flex justify-end pt-10">
						<Button
							style={{ backgroundColor: "orange" }}
							onClick={() => {
								handleAddAttribute();
							}}
						>
							Add
						</Button>
					</div>
				</div>
			</Modal>
			<Modal
				isOpen={editModal}
				title="Edit Attribute"
				onClose={() => setEditModal(false)}
			>
				<div>
					<InputLabel label="Name" />
					<InputField
						value={editData.name}
						onChange={(e) =>
							setEditData((prev) => ({ ...prev, name: e.target.value }))
						}
					/>
				</div>
				<div className="py-5">
					<InputLabel label="Values" />

					{editData?.attribute_description?.map(
						(attribute_description, index) => (
							<div className="py-2 w-400" key={index}>
								<div className="flex">
									<InputField
										value={attribute_description?.value}
										onChange={(e) =>
											setEditData((prev) => ({
												...prev,
												attribute_description: prev.attribute_description.map(
													(item, i) =>
														i === index
															? { ...item, value: e.target.value }
															: item
												),
											}))
										}
									/>
									<div
										className="flex items-center justify-center cursor-pointer h-50 w-50 text-red"
										style={{ width: "50px", height: "50px" }}
										onClick={() =>
											setEditData((prev) => ({
												...prev,
												attribute_description:
													prev.attribute_description.filter(
														(item, i) => i !== index
													),
											}))
										}
									>
										<MdDelete color="red" />
									</div>
								</div>
							</div>
						)
					)}
					<button
						className="bg-[#fff] py-1 px-3 rounded-md"
						onClick={() =>
							setEditData((prev) => ({
								...prev,
								attribute_description: [
									...prev.attribute_description,
									{ value: "" }, // Assuming the object structure only needs a 'value' key
								],
							}))
						}
					>
						Add
					</button>
					<div className="py-3">
						<button
							className="bg-[#8e6524] py-2 px-3 rounded text-white"
							onClick={() => handleEdit()}
						>
							Submit
						</button>
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default Attributes;
