import React from 'react';

const PriceInput = ({ value, onChange }) => {
  const handleChange = (e) => {
    const input = e.target.value;
    // Regex to allow only numbers and optional decimal point
    const regex = /^\d*\.?\d*$/;
    // Check if the input matches the regex pattern
    if (regex.test(input) || input === '') {
      onChange(input);
    }
  };

  return (
    <div className="relative bg-gray-700 w-full">
      <span className="absolute inset-y-0 left-0 flex items-center pl-2 text-gray-300">
        {/* <svg
          className="h-5 w-5 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 8h16M4 16h16M10 12h4"
          />
        </svg> */}
        ₹
      </span>
      <input
        type="text"
        value={value}
        onChange={handleChange}
        className="pl-8 border border-gray-300 rounded-md w-full bg-gray-700 py-2 px-3 focus:outline-none text-white focus:ring focus:border-blue-500"
        placeholder="Enter price"
      />
    </div>
  );
};

export default PriceInput;
