import React, { useState } from "react";
import InputNumber from "../../components/utils/InputNumber";
import { FaCloudUploadAlt } from "react-icons/fa";
import InputLabel from "../../components/utils/InputLabel";
import { MdDeleteSweep } from "react-icons/md";
import api from "../../api";
import TextAreaInput from "../../components/utils/TextAreaInput";
import InputField from "../../components/utils/InputField";
import { useNavigate } from "react-router-dom";

const AddBanner = () => {
  const [bannerData, setBannerData] = useState({
    section: 0,
    slider: false,
    items: [
      {
        image: null,
        filter: "",
        order_id: 0,
      },
    ],
  });
  const [image, setImage] = useState(null);
  const [progress, setProgress] = useState(0);

  const handleImageChange = async (e, index) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("file", file);

      const response = await api.post("main/upload/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          setProgress(progress);
        },
      });
      setBannerData((prev) => {
        const updatedItems = [...prev.items];
        updatedItems[index].image = response.url;
        return { ...prev, items: updatedItems };
      });
    }
  };
  console.log(bannerData);
  const navigate = useNavigate()
  const handleSubmit = () => {
    api.post("marketing/admin/add-banner/", bannerData).then((res) => {
      navigate('/banners')
    });
  };

  return (
    // Start of  Selection
    <div className="relative" style={{ height: "calc(100vh - 80px)" }}>
      <div className="bg-gray-800 rounded py-6 px-3 flex justify-between">
        <div className="w-1/2">
          <h3 className="text-white text-3xl font-semibold">Add Banner</h3>
          <div className="w-1/3 flex justify-between items-center mt-4">
            <h4 className="whitespace-nowrap text-white">section : </h4>
            <div className="w-1/2">
              <InputNumber
                value={bannerData.section}
                onChange={(value) =>
                  setBannerData((prev) => ({ ...prev, section: value }))
                }
              />
            </div>
          </div>
          <div className="w-1/3 flex justify-between items-center mt-4">
            <h4 className="whitespace-nowrap text-white">slider : </h4>
            <div className="w-1/2">
              <input
                type="checkbox"
                checked={bannerData?.slider}
                onChange={(e) =>
                  setBannerData((prev) => ({
                    ...prev,
                    slider: e.target.checked,
                  }))
                }
              />
            </div>
          </div>
        </div>
        <div className="flex items-end">
          <button
            className="bg-gray-400 py-3 px-4 text-gray-200 rounded text-bold"
            onClick={() =>
              setBannerData((prev) => ({
                ...prev,
                items: [
                  ...prev.items,
                  { image: null, filter: "", order_id: 0 },
                ],
              }))
            }
          >
            + Add item
          </button>
        </div>
      </div>
      <div className="max-h-[600px] overflow-y-scroll">
        {bannerData?.items.map((item, index) => (
          <div className="bg-gray-800 p-3 mt-4 rounded" key={index}>
            {item.image ? (
              <img
                src={item.image}
                alt="Banner"
                className="w-full h-[300px] object-contain"
              />
            ) : (
              <div className="flex flex-col items-center justify-center h-[300px] bg-gray-500 rounded-md">
                <FaCloudUploadAlt className="text-gray-800 text-6xl mb-4" />
                <p className="text-gray-800">Drop image here,</p>
                <label
                  htmlFor="file-upload"
                  className="cursor-pointer text-blue-500 hover:underline"
                >
                  Select from library
                </label>
                <input
                  id="file-upload"
                  type="file"
                  className="hidden"
                  onChange={(e) => handleImageChange(e, index)}
                />
              </div>
            )}
            <div className="py-3 flex gap-10">
              <div className="flex w-1/2 justify-between">
                <InputLabel label={"Order"} />
                <div className="w-1/2">
                  <InputNumber
                    value={bannerData.items[index].order_id}
                    onChange={(e) =>
                      setBannerData((prev) => {
                        const updatedItems = [...prev.items];
                        updatedItems[index] = {
                          ...updatedItems[index],
                          order_id: e,
                        };
                        return { ...prev, items: updatedItems };
                      })
                    }
                  />
                </div>
              </div>
              <div className="flex w-1/2 justify-between">
                <InputLabel label={"Filter"} />
                <div className="w-1/2">
                  <InputField
                    value={bannerData.items[index].filter}
                    onChange={(e) =>
                      setBannerData((prev) => {
                        const updatedItems = [...prev.items];
                        updatedItems[index] = {
                          ...updatedItems[index],
                          filter: e.target.value,
                        };
                        return { ...prev, items: updatedItems };
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end">
              <button className="flex items-center gap-3 py-1 px-3 bg-red-500 rounded text-white">
                <MdDeleteSweep />
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>
      <div className="absolute bottom-0 bg-gray-800 w-full py-3 px-3 rounded flex justify-end">
        <button
          className="bg-orange-400 py-2 px-3 rounded text-white"
          onClick={() => handleSubmit()}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default AddBanner;
