import create from 'zustand';

const useAuthStore = create((set) => ({
  token: localStorage.getItem('authToken') || null, 
  setToken: (newToken) => {
    localStorage.setItem('authToken', newToken);
    set((state) => ({ token: newToken }));
  },
  removeToken: () => {
    localStorage.removeItem('authToken');
    set({ token: null });
  },
orderStartDate: null,
orderEndDate: null,
setOrderStartDate: (startDate) => set({ orderStartDate: startDate }),
setOrderEndDate: (endDate) => set({ orderEndDate: endDate }),
}));

export default useAuthStore;
