import React from 'react';
import { MdCheckCircle } from 'react-icons/md';

const SuccessModal = ({ isOpen, onClose, message }) => {
  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center">
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg" style={{width:'40%'}}>
            <div className="flex items-center justify-center text-green-500">
              <MdCheckCircle className="text-6xl" />
            </div>
            <p className="text-center text-gray-300 mt-4">{message}</p>
            <div className="flex justify-center mt-6">
              <button
                onClick={onClose}
                className="bg-green-500 text-white px-4 py-2 rounded-full hover:bg-green-600 focus:outline-none"
              >
                Okay
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SuccessModal;
