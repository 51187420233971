/** @format */

import React from "react";
import { useNavigate } from "react-router-dom";

const useLogout = () => {
	const navigate = useNavigate();

	const logout = () => {
		// Clear local storage (or perform any other necessary cleanup)
		localStorage.clear();

		// Navigate to '/'
		navigate("/auth");
	};

	return { logout };
};

export default useLogout;
