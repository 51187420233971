/** @format */

import React, { useEffect, useState } from "react";
import InputField from "../../components/utils/InputField";
import useGetApi from "../../hooks/useGetApi";
import Table from "../../components/utils/Table";
import Dropdown from "../../components/utils/Dropdown";
import Pagination from "../../components/utils/Pagination";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Modal from "../../components/utils/Modal";
import InputLabel from "../../components/utils/InputLabel";
import api from "../../api";

const Inventory = () => {
	const [filter, setFilter] = useState("all");
	const [refresh, setRefresh] = useState(false);
	const [productId, setProductId] = useState("");
	const [quantityModal, setQuantityModal] = useState(false);
	const [search, setSearch] = useState("");
	const [attributeQuantities, setAttributeQuantities] = useState([]);
	const { data, pagination, setPage } = useGetApi(
		"products/admin/inventory/",
		refresh,
		{
			type: filter,
			search: search,
		}
	);
	console.log(data, "__________");
	useEffect(() => {
		if (productId) {
			api.get(`products/admin/view-stock/${productId}`).then((res) => {
				setAttributeQuantities(res.app_data.data);
				console.log(res);
			});
		}
	}, [productId]);
	console.log(attributeQuantities, "____________");
	const handleSubmit = () => {
		api
			.post("products/admin/update-quantity/", {
				datas: attributeQuantities,
			})
			.then((res) => {
				alert("Successfully updated inventory!");
				setRefresh((prev) => !prev);
				setQuantityModal(false);
			});
	};
	const navigate = useNavigate();
	const columns = React.useMemo(
		() => [
			{
				Header: "Product Code",
				accessor: "product_code",
			},
			{
				Header: "Name",
				accessor: "name",
			},
			{
				Header: "Thumbnail",
				accessor: "id",
				Cell: (row) => {
					return (
						<div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
							<img className="w-full" src={row.row.thumbnail} />
						</div>
					);
				},
			},
			{
				Header: "Stock",
				accessor: "stock",
			},
			{
				Header: "Actions",
				accessor: "id",
				Cell: (row) => {
					console.log(row.row.id, "row_______");
					return (
						<div className="flex item-center justify-start gap-2">
							<span
								className="cursor-pointer"
								onClick={() => {
									setProductId(row.row.id);
									setQuantityModal(true);
								}}
							>
								<FaEdit />
							</span>
						</div>
					);
				},
			},
		],
		[]
	);
	return (
		<div>
			<div className="py-3">
				<h3 className="text-white" style={{ fontSize: 25, fontWeight: 700 }}>
					Inventory
				</h3>
			</div>
			<div className="bg-gray-800 rounded p-4 flex gap-5 justify-between">
				<div className="flex w-1/2 justify-between gap-1">
					<div className="w-1/2">
						<InputField
							placeholder={"Search Product"}
							onChange={(e) => {
								setSearch(e.target.value);
								setRefresh((prev) => !prev);
							}}
						/>
					</div>
					{/* <div className="w-1/2">
            <select
              onChange={(e) => {
                setFilter(e.target.value);
                setRefresh((prev) => !prev);
              }}
              className="w-full h-12 bg-gray-700 border border-gray-500 border-solid text-white rounded"
            >
              <option value="all">All</option>
              <option value="out_of_stock">Out of stock</option>
              <option value="low_stock">Low stock</option>
            </select>
          </div> */}
					<div className="flex items-center gap-3">
						<div
							className={`${
								filter == "all" ? "bg-blue-500" : "bg-gray-700"
							} py-2 px-3 text-[#fff] rounded cursor-pointer`}
							onClick={() => {
								setFilter("all");
								setRefresh((prev) => !prev);
							}}
						>
							ALL
						</div>
						<div
							className={`${
								filter == "out_of_stock" ? "bg-blue-500" : "bg-gray-700"
							} py-2 px-3 text-[#fff] rounded cursor-pointer`}
							onClick={() => {
								setFilter("out_of_stock");
								setRefresh((prev) => !prev);
							}}
						>
							Out of stock
						</div>
						<div
							className={`${
								filter == "low_stock" ? "bg-blue-500" : "bg-gray-700"
							} py-2 px-3 text-[#fff] rounded cursor-pointer`}
							onClick={() => {
								setFilter("low_stock");
								setRefresh((prev) => !prev);
							}}
						>
							Low stock
						</div>
					</div>
				</div>
				<div className="w-1/4 flex gap-2">
					{/* <button className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white bg-emerald-500 border border-transparent active:bg-emerald-600 hover:bg-emerald-600 h-12 w-1/2 bg-emerald-700">
            Filter
          </button> */}
					{/* <button
            className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm border-gray-200 border text-gray-400 focus:outline-none rounded-lg border border-gray-200 px-4 w-1/2 mr-3 flex items-center justify-center cursor-pointer h-12 px-4 md:py-1 py-2 h-12 text-sm bg-gray-700"
            onClick={() => setModal(true)}
          >
            Add Product
          </button> */}
				</div>
			</div>
			<div className="mt-3 rounded overflow-hidden">
				<Table data={data} columns={columns} />
				<Pagination
					totalPages={pagination.total_pages}
					currentPage={pagination.current_page}
					onPageChange={(e) => setPage(e)}
				/>
			</div>
			<Modal
				isOpen={quantityModal}
				onClose={() => setQuantityModal(false)}
				title={"Add stock"}
			>
				{attributeQuantities?.map((item, index) => (
					<div key={index} className="mb-4">
						<InputLabel label={item.attribute_description} />
						<InputField
							value={item.quantity}
							onChange={(e) => {
								const newItem = { ...item, quantity: e.target.value };
								const newAttributeQuantities = [...attributeQuantities];
								newAttributeQuantities[index] = newItem;
								setAttributeQuantities(newAttributeQuantities);
							}}
						/>
					</div>
				))}
				<div className="py-4">
					<button
						className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
						onClick={() => handleSubmit()}
					>
						SUBMIT
					</button>
				</div>
			</Modal>
		</div>
	);
};

export default Inventory;
