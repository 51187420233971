import { useState } from 'react';
import api from '../api';
import useAuthStore from '../store/UserStore';

const useLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
    
  const handleLogin = async () => {
    setError(null);
    setLoading(true);

    try {
      // Make a POST request to your login endpoint
      await api.post('accounts/admin/login/', {
        email,
        password,
      }).then((res)=>{
        console.log(res,"_____res");
        useAuthStore.getState().setToken(res.app_data.data.access);
        // localStorage.setItem('token',res.app_data.data.access)
      });

      

      // Perform actions after successful login, like storing the token in localStorage
    //   localStorage.setItem('token', token);
    } catch (error) {
      setError('Invalid email or password');
    } finally {
      setLoading(false);
    }
  };

  return {
    email,
    setEmail,
    password,
    setPassword,
    loading,
    error,
    handleLogin,
  };
};

export default useLogin;
