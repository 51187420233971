/** @format */

import React from "react";
import NoDataFound from "../../assets/img/notfound.png";

const Table = ({ columns, data }) => {
	return (
		<table className="min-w-full divide-y divide-gray-200 overflow-y-scroll">
			<thead className="bg-gray-800 rounded">
				<tr>
					{columns.map((column, index) => (
						<th
							key={index}
							className="px-6 py-3 text-xs font-medium tracking-wider text-left text-white uppercase"
						>
							{column.Header}
						</th>
					))}
				</tr>
			</thead>
			<tbody className="bg-gray-800 divide-y divide-gray-900">
				{data && data.length > 0 ? (
					data.map((row, rowIndex) => (
						<tr key={rowIndex} className="bg-gray-800">
							{columns.map((column, colIndex) => (
								<td
									key={colIndex}
									className="px-6 py-4 text-sm text-white whitespace-nowrap"
								>
									{column.accessor === "id" && column.Cell
										? column.Cell({ row })
										: row[column.accessor]}
								</td>
							))}
						</tr>
					))
				) : (
					<tr>
						<td colSpan={columns.length} className="py-4 text-center">
							<div className="flex flex-col items-center justify-center">
								<img src={NoDataFound} alt="No Data" className="mt-2" />
								<p className="mb-2 text-gray-500">Oops, no data found</p>
							</div>
						</td>
					</tr>
				)}
			</tbody>
		</table>
	);
};

export default Table;
