import React from 'react';

const TextAreaInput = ({ label, value, onChange, placeholder, rows, cols }) => {
    return (
        <div className="mb-4 w-full">
            <label className="block text-sm font-medium text-gray-700">{label}</label>
            <textarea
                className="p-3 block text-white w-full mt-1 border bg-gray-700 border-gray-600 h-32 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                rows={rows}
                cols={cols}
            ></textarea>
        </div>
    );
};

export default TextAreaInput;
