import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { ImFacebook, ImGoogle } from "react-icons/im";
import { Button } from "@windmill/react-ui";

import ImageLight from "../../assets/img/login-office.jpeg";
import ImageDark from "../../assets/img/login-office-dark.jpeg";
import LabelArea from '../../components/utils/LabelArea';
import Error from '../../components/utils/Error';
import CMButton from '../../components/utils/CMButton';
import InputField from '../../components/utils/InputField';
import PasswordInput from '../../components/utils/PasswordInput';
import SwalError from '../../components/utils/SwalError';
import api from '../../api';
import useLogin from '../../hooks/useLogin';

const LoginScreen = () => {
    // const [loading, setLoading]=useState(false)
    // const [email,setEmail]=useState('')
    // const [password, setPassword]=useState('')
    const { email, setEmail, password, setPassword, loading, error, handleLogin } = useLogin();
    // const handleSubmit=(e) => {
    //     if (email && password){
    //         api.post()
    //     }else{
    //         // setError('Email and password is required!');
    //         alert('email and password is required')
    //     }
    // }
    // const handleErrorClose = () => {
    //     setError('');
    //   };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission
      if (!email || !password){
        alert("Email and password is required")
      }
        // Call handleLogin from the custom hook to handle the login process
        await handleLogin();
      };
  return (
    <div className="flex items-center min-h-screen p-6 bg-gray-900">
        <div className="flex-1 h-full max-w-4xl mx-auto overflow-hidden rounded-lg shadow-xl bg-gray-800">
          <div className="flex flex-col overflow-y-auto md:flex-row">
            <div className="h-32 md:h-auto md:w-1/2">
              <img
                aria-hidden="true"
                className="object-cover w-full h-full dark:hidden"
                src={ImageLight}
                alt="Office"
              />
              <img
                aria-hidden="true"
                className="hidden object-cover w-full h-full dark:block"
                src={ImageDark}
                alt="Office"
              />
            </div>
            <main className="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
              <div className="w-full">
                <h1 className="mb-6 text-2xl font-semibold text-gray-200">
                  Login
                </h1>
                <form onSubmit={handleSubmit}>
                  <LabelArea label="Email" />
                  <InputField placeholder="Enter your Email" type="email" onChange={(e)=>setEmail(e.target.value)}/>
                  <Error errorName={"email is required"} />
                  <div className="mt-6"></div>
                  <LabelArea label="Password" />
                  <PasswordInput placeholder="Enter your password" onChange={(e)=>setPassword(e.target.value)}/>
                  <Error errorName={'password is required'} />
                  {loading ? (
                    <CMButton
                      disabled={loading}
                      type="submit"
                      className={`bg-emerald-600 rounded-md mt-4 h-12 w-full`}
                      to="/dashboard"
                    />
                  ) : (
                    <Button
                      disabled={loading}
                      type="submit"
                      className="mt-4 h-12 w-full bg-blue-500"
                    //   to="/dashboard"
                    >
                     Login to youngsta
                    </Button>
                  )}
                </form>
              </div>
            </main>
          </div>
        </div>
        {/* <SwalError error={error} onClose={handleErrorClose}/> */}
      </div>
  )
}

export default LoginScreen