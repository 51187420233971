import React from 'react';

const RangeInput = ({ from, to, start, end, onStartChange, onEndChange }) => {
  return (
    <div className="flex items-center w-full">
      <label className="ml-2">{to}</label>
      <input
        type="number"
        value={start}
        onChange={onStartChange}
        className="ml-2 mr-2 w-16 border border-gray-300 rounded p-1 text-center"
      />
      <span className="mx-2 text-white">to</span>
      <input
        type="number"
        value={end}
        onChange={onEndChange}
        className="ml-2 w-16 border border-gray-300 rounded p-1 text-center"
      />
    </div>
  );
};

export default RangeInput;
