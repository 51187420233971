import { FaShopify } from "react-icons/fa";
import { FiGrid, FiUsers, FiUser, FiCompass, FiSettings, FiSlack, FiGlobe, FiTarget } from "react-icons/fi";
import { ImProfile } from "react-icons/im";
import { PiFlagBannerFill } from "react-icons/pi";
import { FaHandsHelping } from "react-icons/fa";
/**
 * ⚠ These are used just to render the Sidebar!
 * You can include any link here, local or external.
 *
 * If you're looking to actual Router routes, go to
 * `routes/index.js`
 */
const sidebar = [
  {
    path: "/dashboard", // the url
    icon: FiGrid, // icon
    name: "Dashboard", // name that appear in Sidebar
  },
  {
    path: "/orders",
    icon: FiCompass,
    name: "Orders",
  },
  {
    icon: FiSlack,
    name: "Catalog",
    routes: [
      {
        path: "/products",
        name: "Products",
      },
      {
        path: "/categories",
        name: "Categories",
      },
      // {
      //   path: "/attributes",
      //   name: "Attributes",
      // },
      {
        path: "/coupons",
        name: "Coupons",
      },
      {
        path: "/inventory",
        name: "Inventory",
      },
      {
        path: "/attributes",
        name: "Attributes",
      },
    ],
  },
  {
    path: "/accounts", // the url
    icon: ImProfile, // icon
    name: "Accounts", // name that appear in Sidebar
  },
  {
    path: "/banners",
    icon: PiFlagBannerFill,
    name: "Banners",
  },
  {
    path: "/referals",
    icon: FiCompass,
    name: "Refferals",
  },
  {
    path: "/customers",
    icon: FiUsers,
    name: "Customers",
  },
  {
    path:"/enquiries",
    icon: FaHandsHelping,
    name: "Enquiries",
  }

  // {
  //   // path: "/marketing",
  //   icon: FaShopify,
  //   name: "Marketing",
  //   routes: [
  //     {
  //       path: "/banners",
  //       name: "Banners",
  //     },
  //     {
  //       path: "/offers",
  //       name: "Offers",
  //     },
  //     // {
  //     //   path: "/attributes",
  //     //   name: "Attributes",
  //     // },
  //   ],
  // },

  // {
  //   path: "/our-staff",
  //   icon: FiUser,
  //   name: "OurStaff",
  // },

  // {
  //   path: "/settings?settingTab=common-settings",
  //   icon: FiSettings,
  //   name: "Settings",
  // },
  // {
  //   icon: FiGlobe,
  //   name: "International",
  //   routes: [
  //     {
  //       path: "/languages",
  //       name: "Languages",
  //     },
  //     {
  //       path: "/currencies",
  //       name: "Currencies",
  //     },
  //   ],
  // },
  // {
  //   icon: FiTarget,
  //   name: "OnlineStore",
  //   routes: [
  //     {
  //       name: "ViewStore",
  //       path: "http://localhost:3000",
  //       outside: "store",
  //     },

  //     {
  //       path: "/store/customization",
  //       name: "StoreCustomization",
  //     },
  //     {
  //       path: "/store/store-settings",
  //       name: "Store Settings",
  //     },
  //   ],
  // },

  // {
  //   icon: FiSlack,
  //   name: "Pages",
  //   routes: [
  //     // submenu

  //     {
  //       path: "/404",
  //       name: "404",
  //     },
  //     {
  //       path: "/coming-soon",
  //       name: "Coming Soon",
  //     },
  //   ],
  // },
];

export default sidebar;
