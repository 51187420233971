/** @format */

import React from "react";
import SideBar from "../../components/utils/SideBar";
import Header from "../../components/Header";
import { Navigate, Route, Routes } from "react-router-dom";
import Products from "./Products";
import Categories from "./Categories";
import Costomers from "./Costomers";
import Orders from "./Orders";
import NotFound from "../NotFound";
import ProductSingle from "./ProductSingle";
import Coupens from "./Coupens";
import Attributes from "./Attributes";
import Dashboard from "./Dashboard";
import Banners from "./Banners";
import Offers from "./Offers";
import VarientSingle from "./VarientSingle";
import AddProduct from "./AddProduct";
import EditProduct from "./EditProduct";
import Inventory from "./Inventory";
import EditAccount from "./EditAccount";
import Refferals from "./Refferals";
import RefferalSingle from "./RefferalSingle";
import Accounts from "./Accounts";
import OrderSingle from "./OrderSingle";
import BannersSingle from "./BannersSingle";
import AddBanner from "./AddBanner";
import Enquiries from "./Enquiries";
import CreateOrders from "./CreateOrders";

const Layout = () => {
	return (
		<div
			className="bg-gray-900 flex"
			style={{ height: "100vh", overflow: "hidden" }}
		>
			<SideBar />
			<div className="w-full">
				<Header />
				<div className="p-4 " style={{ height: "95vh", overflowY: "scroll" }}>
					<Routes>
						<Route path="/" element={<Navigate to="/dashboard" />} />
						<Route path="/dashboard" element={<Dashboard />} />
						<Route path="/products" element={<Products />} />
						<Route path="/products/:id" element={<ProductSingle />} />
						<Route
							path="/products/:id/:varientId"
							element={<VarientSingle />}
						/>
						<Route path="/categories" element={<Categories />} />
						<Route path="/add-product" element={<AddProduct />} />
						<Route path="/customers" element={<Costomers />} />
						<Route path="/orders" element={<Orders />} />
						<Route path="/orders/create" element={<CreateOrders />} />
						<Route path="/orders/:id" element={<OrderSingle />} />
						<Route path="/coupons" element={<Coupens />} />
						<Route path="/attributes" element={<Attributes />} />
						<Route path="/banners" element={<Banners />} />
						<Route path="/add-banners" element={<AddBanner />} />
						<Route path="/banners/:id" element={<BannersSingle />} />
						<Route path="/referals" element={<Refferals />} />
						<Route path="/referals/:id" element={<RefferalSingle />} />
						<Route path="/offers" element={<Offers />} />
						<Route path="/inventory" element={<Inventory />} />
						<Route path="/edit-product/:id" element={<EditProduct />} />
						<Route path="/edit-profile" element={<EditAccount />} />
						<Route path="/accounts" element={<Accounts />} />
						<Route path="/enquiries" element={<Enquiries />} />
						<Route path="*" element={<NotFound />} />
					</Routes>
				</div>
			</div>
		</div>
	);
};

export default Layout;
