/** @format */

import React, { useEffect, useState } from "react";
import InputField from "../../components/utils/InputField";
import Table from "../../components/utils/Table";
import useCategoryView from "../../hooks/useCategoryView";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Modal from "../../components/utils/Modal";
import InputLabel from "../../components/utils/InputLabel";
import ImageUploader from "../../components/utils/ImageUploader";
import ImageUploaderOne from "../../components/utils/ImageUploaderOne";
import TextAreaInput from "../../components/utils/TextAreaInput";
import api from "../../api";
import { GrView } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import SideDrawer from "../../components/utils/SideDrawer";
import SubCategorySelector from "../../components/utils/SubCategorySelector";
import { Button } from "@windmill/react-ui";
import useAddSubCategory from "../../hooks/useAddSubCategory";
import EditSubCategorySelector from "../../components/utils/EditSubCategorySelector";

const Categories = () => {
	const [isFileUploadLoading, setFileUploadLoading] = useState(false);
	const [modal, setModal] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const { category, error, loading } = useCategoryView(refresh);
	const [subCategoryData, setSubCategoryData] = useState({});
	const { postSubCategory, setSategoryData } = useAddSubCategory();
	const [subCategoryImage, setSubCategoryImage] = useState("");
	const [editData, setEditData] = useState({});
	console.log(editData, "_______edit helllls");
	// console.log(subCategoryImage, "______");
	const navigate = useNavigate();
	const [categoryDetail, setCategoryDetail] = useState({
		name: "",
		description: "",
		image: "",
	});
	const [oldImage, setOldImage] = useState(null);

	const handleSubmit = () => {
		try {
			if (!categoryDetail.name && !categoryDetail.description) {
				alert("Please fill all fields");
			}
			api
				.post("products/admin/addCategory/", {
					...categoryDetail,
				})
				.then((res) => {
					if (res.app_data.StatusCode === 6000) {
						setCategoryDetail({ name: "", description: "", image: "" });
						setRefresh((prev) => !prev);
						setModal(false);
					} else {
						alert("Error Occured:", res.app_data.data.message);
					}
					console.log(res);
				});
		} catch (err) {
			console.log(error);
		}
	};
	const [editSubCategory, setEditSubCategory] = useState();
	// const [subCategoryImage,setSubCategoryImage] = useState(null)
	const [subModal, setSubModal] = useState(false);
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [refreshSub, setRefreshSub] = useState(false);
	const [parent, setParent] = useState("");
	console.log(category, "_______cat___________");
	const handleSubCategory = (id) => {
		console.log(id, "__________");
		setEditSubCategory(id);
		setSubModal(true);
	};
	const AddSubCategory = async () => {
		if (editSubCategory) {
			// setSubCategoryData({
			//   name: name,
			//   description: description,
			//   category: editSubCategory,
			//   parent_id: parent.key,
			// });
			api
				.post("products/admin/add-subcategory/", {
					name: name,
					description: description,
					category: editSubCategory,
					parent_id: parent.key,
					image: subCategoryImage
				})
				.then((res) => {
					if (res.app_data.StatusCode === 6000) {
						setRefresh((prev) => !prev);
					}
				});
			// setRefreshSub((prev)=>!prev)
			// postSubCategory();
			// console.log("hello");
		}
	};
	const [editModal, setEditModal] = useState(false);
	const [editSubId, setEditSubId] = useState("");
	const columns = React.useMemo(
		() => [
			{
				Header: "Name",
				accessor: "name",
			},
			{
				Header: "description",
				accessor: "description",
			},
			{
				Header: "image",
				accessor: "id",
				Cell: (row) => {
					console.log(row.row.image, "_____row");
					return (
						<div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
							<img className="w-full" src={row.row.image} />
						</div>
					);
				},
			},
			{
				Header: "Add SubCategory",
				accessor: "id",
				Cell: (row) => {
					console.log(row.row.image, "_____row");
					return (
						<div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
							<button
								className="bg-gray-600 p-2 rounded"
								onClick={() => handleSubCategory(row.row.id)}
							>
								Add Sub category
							</button>
						</div>
					);
				},
			},
			{
				Header: "Actions",
				accessor: "id",
				Cell: (row) => {
					console.log(row.row.id, "row_______");
					const handleDelete = () => {
						api
							.delete(`products/admin/deleteCategory/${row.row.id}/`)
							.then((res) => {
								setRefresh((prev) => !prev);
							});
					};
					return (
						<div className="flex item-center justify-center gap-2">
							{/* <span className="cursor-pointer">
                <FaEdit />
              </span> */}
							<span
								className="cursor-pointer"
								onClick={() => {
									setEditModal(true);
									setEditData(row.row);
									setOldImage(row.row?.image);
								}}
							>
								<FaEdit />
							</span>
							<span className="cursor-pointer" onClick={() => handleDelete()}>
								<MdDelete />
							</span>
							{/* <span
                className="cursor-pointer"
                onClick={() => navigate(`/products/${row.row.id}`)}
              >
                <GrView />
              </span> */}
						</div>
					);
				},
			},
		],
		[]
	);

	const [editSubData, setEditSubData] = useState({});
	const [editSubModal, setEditSubModal] = useState(false);
	const handleEditCategory = () => {
		api
			.put(`products/admin/edit-category/${editData.id}/`, {
				...editData,
			})
			.then((res) => {
				setRefresh((prev) => !prev);
				setEditModal(false);
				console.log(res);
			});
	};
	console.log(editSubData, "_____Data");
	useEffect(() => {
		if (editSubId) {
			api.get(`products/view-sub-category-item/${editSubId}/`).then((res) => {
				setEditSubData(res.app_data.data);
			});
		}
	}, [editSubId]);
	const handleEditSubSubmit = () => {
		const formData = new FormData()

		formData.append("name", editSubData?.name)
		formData.append("description", editSubData?.description)

		if (editSubData?.new_image) {
			formData.append("image", editSubData?.new_image)
		}

		api
			.post(`products/edit-sub-category-item/${editSubId}/`, formData)
			.then((res) => {
				setRefresh((prev) => !prev);
				setEditSubModal(false);
			});
	};
	const handleDeleteSubCategory = () => {
		api.delete(`products/admin/delete-subcategory/${editSubId}`).then((res) => {
			setRefresh((prev) => !prev);
			setEditSubModal(false);
		});
	};
	const [selectedFile, setSelectedFile] = useState();
	const [selCatImage, setSelCatImage] = useState();

	const handleFileChange = async (e) => {
		const file = e.target.files[0];
		setSelCatImage(file);
		const formData = new FormData();
		formData.append("file", file);
		const response = await api.post("main/upload/", formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
		if (response && response.url) {
			console.log(response.url, "check_____");
			const imageUrl = response.url;
			setEditData((prev) => ({ ...prev, image: imageUrl }));
			setSelCatImage(imageUrl);
			console.log(selCatImage, "check_____");
		}
		// try {
		//   // Make an API request to upload the file
		//   const response = await api.uploadImage(formData);
		//   const imageUrl = response.data.url;
		//   setEditData((prev) => ({ ...prev, image: imageUrl }));
		// } catch (error) {
		//   console.error("Error uploading image:", error);
		// }
	};
	const [previewImage, setPreviewImage] = useState(null);

	const handleImageChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onloadend = () => {
				setPreviewImage(reader.result);
			};
			reader.readAsDataURL(file);
		} else {
			setPreviewImage(null);
		}
	};
	// const handleAddFileChange = async (e) => {
	//   const file = e.target.files[0];

	//   try {
	//     setSelectedFile(file);
	//     const formData = new FormData();
	//     formData.append("file", file);

	//     // Make an API request to upload the file
	//     const response = await api.uploadImage(formData);
	//     const imageUrl = response.data.url;
	//     setCategoryDetail((prev) => ({ ...prev, image: imageUrl }));

	//     // Create object URL and log it
	//     try {
	//       if (file) {
	//         const fileUrl = URL.createObjectURL(file);
	//         console.log("Object URL for uploaded file:", fileUrl);
	//       } else {
	//         console.error("File object is null or undefined");
	//       }
	//     } catch (urlError) {
	//       console.error("Error creating object URL:", urlError);
	//     }
	//   } catch (error) {
	//     console.error("Error setting selected file:", error);
	//   }
	// };
	const handleAddFileChange = async (e) => {
		console.log("check____");
		setFileUploadLoading(true);
		try {
			const file = e.target.files[0];
			if (file) {
				const formData = new FormData();
				formData.append("file", file);
				const response = await api.post("main/upload/", formData, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				});
				if (response && response.url) {
					console.log(response, response.url, "check____");
					setCategoryDetail((prev) => ({ ...prev, image: response.url }));
					console.log(categoryDetail, "check____");
					setSelectedFile(response.url);
					console.log(selectedFile, "check____");
					setEditData((prev) => ({ ...prev, image: response.url }));
					console.log(editData, "check____");
					setEditSubData(prev => ({ ...prev, new_image: response.url }))
					setSubCategoryImage(response.url)
				}
				setFileUploadLoading(false);
			}
		} catch (error) {
			setFileUploadLoading(false);
			alert(error.message);
		}
	};
	console.log(selectedFile, "________cat detail");
	return (
		<div>
			<div className="py-3">
				<h3 className="text-white" style={{ fontSize: 25, fontWeight: 700 }}>
					Categories
				</h3>
			</div>
			<div className="bg-gray-800 rounded p-4 flex gap-5 justify-between">
				<div className="w-1/4">
					{/* <InputField placeholder={"Search Product"} /> */}
				</div>
				{/* <div className="w-1/5">
          <InputField placeholder={"Category"} />
        </div>
        <div className="w-1/4">
          <InputField placeholder={"Price"} />
        </div> */}
				<div className="flex gap-2">
					{/* <button className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white bg-emerald-500 border border-transparent active:bg-emerald-600 hover:bg-emerald-600 h-12 w-1/2 bg-emerald-700">
            Filter
          </button> */}
					<button
						className="w-200 align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm border-gray-200 border text-gray-400 focus:outline-none rounded-lg border border-gray-200 px-4 w-1/2 mr-3 flex items-center justify-center cursor-pointer h-12 px-4 md:py-1 py-2 h-12 text-sm bg-gray-700"
						onClick={() => setModal(true)}
					>
						Add Category
					</button>
				</div>
			</div>
			<div className="bg-gray-800 rounded mt-3 overflow-hidden">
				{category && <Table columns={columns} data={category} />}
			</div>

			<SideDrawer
				title={"Add Category"}
				isOpen={modal}
				onClose={() => setModal(false)}
			>
				<div style={{ width: "500px" }}>
					<div className="flex">
						<InputLabel label="Name" />
						<InputField
							value={categoryDetail.name}
							onChange={(e) =>
								setCategoryDetail((prev) => ({ ...prev, name: e.target.value }))
							}
						/>
					</div>
					<div className="flex py-5">
						<InputLabel label="Description" />
						<TextAreaInput
							value={categoryDetail.description}
							onChange={(e) =>
								setCategoryDetail((prev) => ({
									...prev,
									description: e.target.value,
								}))
							}
						/>
					</div>
					<div className="flex py-5">
						<InputLabel label="Image" />
						{/* <ImageUploaderOne onChange={(e) => setCategoryDetail((prev) => ({ ...prev, image: e.url }))} /> */}
						<div>
							<input
								type="file"
								accept="image/*"
								onChange={handleAddFileChange}
							/>
							{selectedFile && (
								<div>
									{/* <h4>New image</h4> */}
									{/* <img src={selectedFile} alt="Preview" width="200" height="200" /> */}
								</div>
							)}
						</div>
					</div>
					<div className="flex justify-end py-4">
						<button
							disabled={isFileUploadLoading}
							onClick={() => handleSubmit()}
							className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded"
						>
							Add Category
						</button>
					</div>
				</div>
			</SideDrawer>
			<SideDrawer isOpen={subModal} onClose={() => setSubModal(false)}>
				<div>
					<div>
						<SubCategorySelector
							catId={editSubCategory}
							onSelect={(e) => setParent(e)}
							refreshSub={refresh}
						/>
					</div>
					<div>
						<InputLabel label={"Name"} />
						<InputField onChange={(e) => setName(e.target.value)} />
					</div>
					<div>
						<InputLabel label="description" />
						<TextAreaInput onChange={(e) => setDescription(e.target.value)} />
					</div>
					<div className="pb-10">
						<input type="file" onChange={e =>{
							handleImageChange(e)
							handleAddFileChange(e)
						}} />
						{previewImage && (
							<div className="mt-4">
								<h2>Preview:</h2>
								<img
									src={previewImage}
									alt="Preview"
									style={{ maxWidth: "100%", maxHeight: "200px" }}
								/>
							</div>
						)}
					</div>
					<div style={{ display: "sticky", bottom: 0 }}>
						<Button
							disabled={isFileUploadLoading}
							style={{ backgroundColor: "orange" }}
							onClick={() => AddSubCategory()}
						>
							Add
						</Button>
					</div>
				</div>
			</SideDrawer>

			<SideDrawer
				isOpen={editModal}
				onClose={() => setEditModal(false)}
				title="Edit Category"
			>
				<div>
					<div>
						<EditSubCategorySelector
							catId={editData.id}
							onSelect={(e) => setEditSubId(e.key)}
							refreshSub={refresh}
						/>
						<div className="py-3">
							{editSubId && (
								<button
									className="py-1 px-3 rounded"
									style={{ backgroundColor: "orange" }}
									onClick={() => {
										setEditSubModal(true);
									}}
								>
									Edit
								</button>
							)}
						</div>
					</div>
					<div>
						<InputLabel label={"Name"} />
						<InputField
							onChange={(e) =>
								setEditData((prev) => ({ ...prev, name: e.target.value }))
							}
							value={editData.name}
						/>
					</div>
					<div>
						<InputLabel label="description" />
						<TextAreaInput
							onChange={(e) =>
								setEditData((prev) => ({
									...prev,
									description: e.target.value,
								}))
							}
							value={editData.description}
						/>
					</div>
					<div className="pb-10 flex space-x-5 items-center pt-5">
						<div>
							<input type="file" accept="image/*" onChange={handleFileChange} />
							{console.log(selectedFile, "____selgerfile")}
							{selCatImage && (
								<div>
									{console.log(selectedFile)}
									<h4>New image</h4>
									<img
										src={selCatImage}
										alt="Selected File Preview"
										width="200"
										height="200"
									/>
								</div>
							)}
						</div>
						<div className="w-50">
							<h4>Old image</h4>
							<img className="w-20 h-20" src={oldImage} />
						</div>
					</div>
					<div style={{ display: "sticky", bottom: 0 }}>
						<Button
							style={{ backgroundColor: "orange" }}
							onClick={() => handleEditCategory()}
						>
							Submit
						</Button>
					</div>
				</div>
			</SideDrawer>

			<Modal
				isOpen={editSubModal}
				onClose={() => setEditSubModal(false)}
				title="Edit SubCategory"
			>
				<div className="w-400 py-3">
					<InputLabel label="Name" />
					<InputField
						value={editSubData.name}
						onChange={(e) =>
							setEditSubData((prev) => ({ ...prev, name: e.target.value }))
						}
					/>
				</div>
				<div className="py-3">
					<InputLabel label="Image" />
					{/* <Button className="bg-[#808080] mt-2">Select File</Button> */}
					<input type="file" className="mt-2" placeholder="Choose Image"
						onChange={e => {
							handleAddFileChange(e)
							// if (e.target.files.length) {
							// 	setEditSubData((prev) => ({ ...prev, new_image: e.target.files[0] }))
							// }
						}}
					/>
					{editSubData?.image && (
						<>
							<span className="text-white">Current Image</span>
							<div className="rounded overflow-hidden bg-grey mt-2">
								<img src={editSubData?.image} className="w-full min-h-[100px] max-h-[100px] object-contain" />
							</div>
						</>
					)}
				</div>
				<div className="py-3">
					<InputLabel label="Description" />
					<TextAreaInput
						value={editSubData.description}
						onChange={(e) =>
							setEditSubData((prev) => ({
								...prev,
								description: e.target.value,
							}))
						}
					/>
				</div>
				<div className="space-x-3">
					<button
						disabled={isFileUploadLoading}
						className="py-1 px-3 text-white rounded"
						style={{ backgroundColor: "orange" }}
						onClick={() => handleEditSubSubmit()}
					>
						Submit
					</button>
					<button
						className="py-1 px-3 text-white rounded"
						style={{ backgroundColor: "red" }}
						onClick={() => handleDeleteSubCategory()}
					>
						Delete
					</button>
				</div>
			</Modal>
		</div>
	);
};

export default Categories;
