import React, { useState } from "react";
import InputField from "../../components/utils/InputField";
import useCostomerView from "../../hooks/useCostomerView";
import Table from "../../components/utils/Table";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import useGetApi from "../../hooks/useGetApi";
import Pagination from "../../components/utils/Pagination";

const Costomers = () => {
  const [modal, setModal] = useState();
  //   const [costomers,setCostomers]=useState()
  const [search, setSearch]=useState('')

  const { data: costomer, error, loading, setPage, pagination } = useGetApi(`accounts/admin/costomers/?search=${search}`);
  console.log(costomer, "_________");
  const columns = React.useMemo(
    () => [
      {
        Header: "First name",
        accessor: "first_name",
      },
      {
        Header: "Last name",
        accessor: "last_name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone number",
        accessor: "phone_number",
      },
    ],
    []
  );
  return (
    <div>
      <div className="py-3">
        <h3 className="text-white" style={{ fontSize: 25, fontWeight: 700 }}>
          Customers
        </h3>
      </div>
      <div className="flex gap-5 p-4 bg-gray-800 rounded">
        <div className="w-1/4">
          <InputField placeholder={"Search Customers"} onChange={(e)=>setSearch(e.target.value)}/>
        </div>
      </div>
      <div className="mt-3 overflow-hidden bg-gray-800 rounded">
        <Table columns={columns} data={costomer} />
        <Pagination
          totalPages={pagination.total_pages}
          currentPage={pagination.current_page}
          onPageChange={(e) => setPage(e)}
        />
      </div>
    </div>
  );
};

export default Costomers;
