// SideDrawer.js

import React from "react";
import Drawer from "rc-drawer";
import "rc-drawer/assets/index.css"; // Make sure to include the rc-drawer styles
import "./SideDrawer.css";

const SideDrawer = ({ isOpen, onClose, title, children }) => {
  return (
    <Drawer
      level={null}
      open={isOpen}
      onClose={onClose}
      placement="right"
      handler={false}
      width="600"
      className="drawer-content"
    >
      <div className="flex justify-between p-5 align-center" style={{borderBottom:'1px solid #fff'}}>
        <div className="">
          <h3 style={{ fontSize: "34px", fontWeight: 700 }}>
            {title}
          </h3>
        </div>
        <button className="close-btn" onClick={onClose}>
          X
        </button>
      </div>
      <div className="p-5">{children}</div>
    </Drawer>
  );
};

export default SideDrawer;
