import React, { useState } from 'react';

const Modal = ({ isOpen, onClose,title, children }) => {
    console.log(isOpen);
//   const [modalOpen, setModalOpen] = useState(isOpen);

  const closeModal = () => {
    // setModalOpen(false);
    onClose && onClose();
  };

  return (
    <>
      {isOpen && (
        <div className="fixed blur-background backdrop-blur-md inset-0 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none" style={{zIndex:4000}}>
          <div className="relative w-auto  mx-auto my-6 z-200">
            <div className="bg-gray-800 rounded-lg shadow-lg outline-none focus:outline-none" style={{background: 'rgba(255, 255, 255, 0.15)', backdropFilter: 'blur(10px)'}}>
              <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                <h3 className="text-xl font-semibold text-white">{title}</h3>
                <button
                  className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={closeModal}
                >
                  <span className="block w-6 h-6 text-2xl text-white">×</span>
                </button>
              </div>
              <div className="relative p-6">{children}</div>
            </div>
          </div>
          {/* <div
            className="fixed inset-0 z-40 bg-black opacity-25"
            // onClick={closeModal}
          ></div> */}
        </div>
      )}
    </>
  );
};

export default Modal;
