import React, { useEffect, useState } from "react";
import InputField from "../../components/utils/InputField";
import PasswordInput from "../../components/utils/PasswordInput";
import useGetApi from "../../hooks/useGetApi";
import Modal from "../../components/utils/Modal";
import InputLabel from "../../components/utils/InputLabel";
import api from "../../api";

const EditAccount = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [accountData, setAccountData] = useState({});
  const [oldPass,setOldPass]=useState('')
  const [newPass,setNewPass]=useState('')
  const [confPass,setConfPass]=useState('')
  const [modal, setModal] = useState(false);
  const { data } = useGetApi("accounts/admin/account-details/");
  useEffect(() => {
    if (data) {
      setAccountData(data);
    }
  }, [data]);
  const handleSubmit = () => {
    console.log('hello world');
    api
      .post("accounts/admin/edit-account/", {
        name: accountData.name,
      })
      .then((res) => {
        console.log(res);
      });
  };
  const handleChangePass = () => {
    if (newPass === confPass){
        api.post('accounts/admin/change-password/',{
            old_password:oldPass,
            new_password:newPass
        }).then((res)=>{
            if (res.app_data.StatusCode ===6000){
                alert('Succesfully changed password')
                setModal(false)
            }else{
                alert("Password mismatch")
            }
        })
    }else{
        alert("Password mismatch")
    }
  }
  console.log(data);
  return (
    <div>
      <div className="pl-7">
        <h4 className="text-gray-50 text-3xl">Edit Profile</h4>
      </div>
      <div className="w-1/2">
        <div className="md:mt-0 md:col-span-2">
          {/* <form> */}
          <div className="shadow sm:rounded-md sm:overflow-hidden">
            <div className="space-y-6 sm:p-6">
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Name
                </label>
                <InputField
                  value={accountData?.name}
                  onChange={(e) =>
                    setAccountData({ ...accountData, name: e.target.value })
                  }
                />
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <InputField
                  value={accountData?.email}
                  style={{ cursor: "not-allowed" }}
                  disabled
                />
              </div>

              {/* <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Password
                  </label>
                  <PasswordInput />
                </div> */}
            </div>
            <div className="px-4 py-3 text-right sm:px-6">
              <button
                className="inline-flex mr-4 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => setModal(true)}
              >
                Change Password
              </button>
              <button
                onClick={() => handleSubmit()}
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Save
              </button>
            </div>
          </div>
          {/* </form> */}
        </div>
      </div>
      <Modal
        isOpen={modal}
        onClose={() => setModal(false)}
        title="Change Password"
      >
        <div className="flex items-center">
          <InputLabel label="Current Password" />
          <PasswordInput onChange={(e)=>setOldPass(e.target.value)}/>
        </div>
        <div className="flex items-center">
          <InputLabel label="New Password" />
          <PasswordInput onChange={(e)=>setNewPass(e.target.value)}/>
        </div>
        <div className="flex items-center">
          <InputLabel label="Retype Password" />
          <PasswordInput onChange={(e)=>setConfPass(e.target.value)}/>
        </div>
        <div className="flex justify-end">
          <button
            onClick={() => handleChangePass()}
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Submit
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default EditAccount;
