import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuthStore from '../../store/UserStore';


const Authroute = ({children}) => {
    const token = useAuthStore((state) => state.token);
    return !token ? children : <Navigate to='/' />;
};

export default Authroute;