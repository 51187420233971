/** @format */

import axios from "axios";
import Cookies from "js-cookie";

// export const BASE_URL = "http://localhost:8000/api/v1/";
export const BASE_URL = "https://api.youngsta.in/api/v1/";

const instance = axios.create({
	baseURL: BASE_URL,
	timeout: 50000,
	headers: {
		Accept: "application/json",
		"Content-Type": "application/json",
	},
});

// Add a request interceptor
instance.interceptors.request.use(function (config) {
	// Do something before request is sent
	let adminInfo;
	if (localStorage.getItem("adminInfo")) {
		adminInfo = JSON.parse(localStorage.getItem("adminInfo"));
	}
	let authToken;

	if (localStorage.getItem("authToken")) {
		authToken = localStorage.getItem("authToken");
	}

	return {
		...config,
		headers: {
			authorization: authToken ? `Bearer ${authToken}` : null,
		},
	};
});

// Add a response interceptor to handle 401 errors
instance.interceptors.response.use(
	(response) => response,
	(error) => {
		if (error.response.status === 401) {
			// Handle 401 Unauthorized error here
			console.log("Unauthorized! Logging out...");
			// Clear localStorage or perform logout logic
			localStorage.clear();
			// Redirect to the login page or any desired route
			window.location.href = "/auth";
		}
		return Promise.reject(error);
	}
);

const responseBody = (response) => response.data;

const api = {
	get: (url, body, headers) =>
		instance.get(url, body, headers).then(responseBody),

	post: (url, body) => instance.post(url, body).then(responseBody),

	put: (url, body, headers) =>
		instance.put(url, body, headers).then(responseBody),

	patch: (url, body) => instance.patch(url, body).then(responseBody),

	delete: (url, body) => instance.delete(url, body).then(responseBody),
};

export default api;