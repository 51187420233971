import React from "react";
import {
  Button,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Toolbar,
  Hidden,
  IconButton,
} from "@material-ui/core";
import sidebar from "../../utils/sidebar";
import { IoLogOutOutline, IoMenu } from "react-icons/io5";
import { NavLink, useLocation } from "react-router-dom";
import SidebarSubMenu from "./SidebarSubMenu";
import { useStore } from "../../store/SideStore";
import useLogout from "../../hooks/useLogout";
import useGetApi from "../../hooks/useGetApi";
import { Badge } from "@windmill/react-ui";

const isSmallDevice = window.innerWidth <= 768;

const drawerWidth = !isSmallDevice ? 200 : 50;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    backgroundColor: "#1f2937",
  },
  smallDrawer: {
    width: 80,
    flexShrink: 0,
    backgroundColor: "#1f2937",
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#1f2937",
  },
  toolbar: theme.mixins.toolbar,
  logoutButton: {
    marginTop: "auto",
  },
  activeRoute: {
    backgroundColor: "#101827",
  },
}));

const closeDrawerWidth = 100;

const useCloseStyles = makeStyles((theme) => ({
  drawer: {
    width: closeDrawerWidth,
    flexShrink: 0,
    backgroundColor: "#1f2937",
  },
  drawerPaper: {
    width: closeDrawerWidth,
    backgroundColor: "#1f2937",
  },
  toolbar: theme.mixins.toolbar,
  logoutButton: {
    marginTop: "auto",
  },
  activeRoute: {
    backgroundColor: "#101827",
  },
}));

const SideBar = () => {
  const classes = useStyles();
  const location = useLocation();
  console.log(location.pathname, "_______");
  const isOpen = useStore((state) => state.isOpen);
  const toggleSidebar = useStore((state) => state.toggleSidebar);
  const { logout } = useLogout();
  const { data, allData } = useGetApi("activities/admin/orders/");
  console.log(isOpen, "______");
  return (
    <>
      {/* <Hidden mdUp>
        <IconButton onClick={toggleSidebar} color="inherit">
          <IoMenu />
        </IconButton>
      </Hidden> */}

      <Hidden mdUp implementation="css">
        <Drawer
          className={classes.drawer}
          anchor="left"
          open={isOpen}
          variant="permanent"
          classes={{
            paper: `${classes.drawerPaper} bg-gray-800`, // Add bg-gray-800 here
          }}
          onClose={useCloseStyles}
        >
          <div className="w-full py-2 px-3">
            <img
              src="https://youngsta.s3.eu-north-1.amazonaws.com/logo-color.png"
              className="w-80 object-contain"
              alt="Youngsta"
            />
          </div>
          {/* <Toolbar /> */}
          <List>
            {sidebar.map((route) =>
              route.routes ? (
                <SidebarSubMenu route={route} key={route.name} />
              ) : (
                <ListItem
                  button
                  // selected={location.pathname === route.path}
                  component={NavLink}
                  exact
                  to={route.path}
                  target={`${route?.outside ? "_blank" : "_self"}`}
                  className={`text-sm font-semibold hover:text-emerald-700 ${
                    location.pathname === route.path ? "text-emerald-600" : "text-white"
                  } ${location.pathname === route.path ? classes.activeRoute : ""}`}
                  activeClassName="text-emerald-500"
                  key={route.name}
                >
                  {route.icon && (
                    <route.icon className="w-5 h-5 text-white hover:text-emerald-600" aria-hidden="true" />
                  )}
                  {/* <ListItemText className="text-white" primary={`${route.name} ${route.name === 'Orders' ? `(${allData?.pending_orders ? allData?.pending_orders : 0})` : ""}`} /> */}
                </ListItem>
              )
            )}
          </List>
          <div className={classes.logoutButton} style={{ marginBottom: "10px", padding: "0px 20px" }}>
            {/* Your logout button */}
            <Button
              size="large"
              fullWidth
              variant="contained"
              style={{ backgroundColor: "#fff", color: "#000" }}
              onClick={() => logout()}
            >
              <IoLogOutOutline className="mr-3 text-lg" style={{ color: "#000" }} />
              LogOut
            </Button>
          </div>
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          className={classes.drawer}
          anchor="left"
          open={isOpen}
          variant="permanent"
          classes={{
            paper: `${classes.drawerPaper} bg-gray-800`, // Add bg-gray-800 here
          }}
          onClose={useCloseStyles}
        >
          <div className="w-full py-2 px-3">
            <img
              src="https://youngsta.s3.eu-north-1.amazonaws.com/logo-color 4.png"
              className="w-full object-contain"
              alt="Youngsta"
            />
          </div>
          {/* <Toolbar /> */}
          <List>
            {sidebar.map((route) =>
              route.routes ? (
                <SidebarSubMenu route={route} key={route.name} />
              ) : (
                <ListItem
                  button
                  // selected={location.pathname === route.path}
                  component={NavLink}
                  exact
                  to={route.path}
                  target={`${route?.outside ? "_blank" : "_self"}`}
                  className={`text-sm font-semibold hover:text-emerald-700 ${
                    location.pathname === route.path ? "text-emerald-600" : "text-white"
                  } ${location.pathname === route.path ? classes.activeRoute : ""}`}
                  activeClassName="text-emerald-500"
                  key={route.name}
                >
                  {route.icon && (
                    <route.icon className="w-5 h-5 text-white hover:text-emerald-600 mr-3" aria-hidden="true" />
                  )}
                  <ListItemText
                    className="text-white"
                    primary={
                      <React.Fragment>
                        {route.name}
                        {route.name === "Orders" && ( // Check if route.name is 'Orders'
                          <Badge fi
                            color="secondary" // Adjust color according to your design
                            badgeContent={allData?.pending_orders ? allData.pending_orders : 0}
                            anchorOrigin={{
                              // Adjust anchorOrigin for badge positioning
                              vertical: "top",
                              horizontal: "right",
                            }}
                          />
                        )}
                      </React.Fragment>
                    }
                  />
                </ListItem>
              )
            )}
          </List>
          <div className={classes.logoutButton} style={{ marginBottom: "10px", padding: "0px 20px" }}>
            {/* Your logout button */}
            <Button
              size="large"
              fullWidth
              variant="contained"
              style={{ backgroundColor: "#fff", color: "#000" }}
              onClick={() => logout()}
            >
              <IoLogOutOutline className="mr-3 text-lg" style={{ color: "#000" }} />
              LogOut
            </Button>
          </div>
        </Drawer>
      </Hidden>
    </>
  );
};

export default SideBar;
