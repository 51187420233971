/** @format */

export const PAYMENT_METHODS = [
	{
		title: "COD",
		slug: "cod",
	},
	{
		title: "Card",
		slug: "card",
	},
	{
		title: "Online Transaction",
		slug: "ot",
	},
];
