import React, { useEffect, useState } from "react";
import Tree from "rc-tree";
import "rc-tree/assets/index.css";
import api from "../../api";
import { FaEdit } from "react-icons/fa";

const EditSubCategorySelector = ({ catId, onSelect, refreshSub }) => {
  const [treeData, setTreeData] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`products/view-subcategory/${catId}`);
        setTreeData(response.app_data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [catId, refreshSub]);

  const handleSelect = (selectedKeys, info) => {
    const selectedId = info.node.key;
    setSelectedItemId(selectedId);
    onSelect && onSelect(info.node);
  };

  const renderTreeNodes = (nodes) => {
    return nodes.map((node) => {
      if (node.children && node.children.length) {
        return (
          <Tree.TreeNode key={node.id} title={<span style={{ color: "white", display: 'flex' }}>{node.name}</span>}>
            {renderTreeNodes(node.children)}
            <span
              className="cursor-pointer"
              // onClick={() => {
              //   setEditModal(true);
              //   setEditData(row.row);
              // }}
            >
              {/* <FaEdit /> */}
            </span>
          </Tree.TreeNode>
        );
      }
      return (
        <Tree.TreeNode
          key={node.id}
          style={{ display: "flex" }}
          title={
            <span style={{ color: "white", display: 'flex' , gap:5, alignItems:'center'}}>
              {node.name}
              <span
                className="cursor-pointer"
                // onClick={() => {
                //   setEditModal(true);
                //   setEditData(row.row);
                // }}
              >
                {/* <FaEdit /> */}
              </span>
            </span>
          }
        />
      );
    });
  };

  return (
    <Tree defaultExpandAll onSelect={handleSelect} style={{ display: "flex",gap:10 }}>
      {renderTreeNodes(treeData)}
      <span
        className="cursor-pointer"
        // onClick={() => {
        //   setEditModal(true);
        //   setEditData(row.row);
        // }}
      >
        {/* <FaEdit /> */}
      </span>
    </Tree>
  );
};

export default EditSubCategorySelector;
