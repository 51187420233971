/** @format */

import React, { useEffect, useState } from "react";
import Tree from "rc-tree";
import "rc-tree/assets/index.css";
import api from "../../api";

const SubCategorySelector = ({ catId, onSelect, refreshSub }) => {
	const [treeData, setTreeData] = useState([]);
	const [selectedItemId, setSelectedItemId] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await api.get(`products/view-subcategory/${catId}`);
				setTreeData(response.app_data.data);
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		};

		fetchData();
	}, [catId, refreshSub]);

	const handleSelect = (selectedKeys, info) => {
		const selectedId = info.node.key;
		setSelectedItemId(selectedId);
		onSelect && onSelect(info.node);
		console.log(info, "info===================");
	};

	const renderTreeNodes = (nodes) => {
		return nodes.map((node) => {
			if (node.children && node.children.length) {
				return (
					<Tree.TreeNode
						key={node.id}
						title={<span style={{ color: "white" }}>{node.name}</span>}
					>
						{renderTreeNodes(node.children)}
					</Tree.TreeNode>
				);
			}
			return (
				<Tree.TreeNode
					key={node.id}
					title={<span style={{ color: "white" }}>{node.name}</span>}
				/>
			);
		});
	};

	return (
		<Tree defaultExpandAll onSelect={handleSelect}>
			{renderTreeNodes(treeData)}
		</Tree>
	);
};

export default SubCategorySelector;
