import React, { useState } from 'react';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

const PasswordInput = ({ label, ...rest }) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="mb-4">
      {label && <label className="block text-gray-700 text-sm font-bold mb-2">{label}</label>}
      <div className="relative">
        <input
          className="appearance-none block text-gray-50 w-full bg-gray-700 text-gray-400 border border-gray-600 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-gray-700 focus:border-gray-400"
          type={showPassword ? 'text' : 'password'}
          {...rest}
        />
        <div
          className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
          onClick={togglePasswordVisibility}
        >
          {showPassword ? <AiFillEyeInvisible color="white"/> : <AiFillEye color="white"/>}
        </div>
      </div>
    </div>
  );
};

export default PasswordInput;
