import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useGetApi from "../../hooks/useGetApi";
import InputLabel from "../../components/utils/InputLabel";
import InputField from "../../components/utils/InputField";
import { MdDeleteSweep } from "react-icons/md";
import InputNumber from "../../components/utils/InputNumber";
import api from "../../api";
import { FaCloudUploadAlt } from "react-icons/fa";

const BannersSingle = () => {
  const columns = React.useMemo(() => [
    {
      Header: "S.no",
      accessor: "index",
    },
    {
      Header: "Order",
      accessor: "order_id",
    },
    {
      Header: "filter",
      accessor: "filter",
    },
    {
      Header: "Image",
      accessor: "image",
    },
    {
      Header: "Action",
      accessor: "id",
    },
  ]);
  const { id } = useParams();
  const { data } = useGetApi(`marketing/admin/view-banners/${id}/`);
  const [progress, setProgress] = useState(0);
  const [bannerData, setBannerData] = useState();
  useEffect(() => {
    setBannerData(data);
  }, [data]);
  const handleImageChange = async (e, index) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("file", file);

      const response = await api.post("main/upload/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          setProgress(progress);
        },
      });
      setBannerData((prev) => {
        const updatedItems = [...prev.items];
        updatedItems[index].image = response.url;
        return { ...prev, items: updatedItems };
      });
    }
  };
  const handleSubmit = (item) => {
    console.log(item);
    api.post(`marketing/admin/update-banners/${item.id}/`, item);
  }
  console.log(data, "__data__");
  return (
    <div>
      <div className="bg-gray-800 py-3 px-3 rounded flex justify-between">
        <div>
          <h2 className="text-lg font-semibold text-white">
            section : {data?.section}
          </h2>
          <h2 className="text-lg font-semibold text-white">
            slider : {String(data?.slider)}
          </h2>
        </div>
        <div>
          <button className="bg-orange-400 py-2 px-3 rounded text-white">
            +Add
          </button>
        </div>
      </div>
      <div className="">
        {bannerData?.items.map((item, index) => (
          <div className="bg-gray-800 p-3 mt-4 rounded" key={index} onChange={(e)=>handleImageChange(e,index)}>
            {item.image ? (
              <div>
                <input type="file" id={`file-upload-${index}`} className="hidden"/> 
                <button 
                  className="bg-orange-400 py-2 px-3 text-white font-bold rounded float-end"
                  onClick={() => {
                    const fileInput = document.getElementById(`file-upload-${index}`);
                    if (fileInput) {
                      fileInput.click();
                    } else {
                      console.error(`File input with id file-upload-${index} not found`);
                    }
                  }}
                >
                  Change
                </button>
                <img
                  src={item.image}
                  alt="Banner"
                  className="w-full h-[300px] object-contain"
                />
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center h-[300px] bg-gray-500 rounded-md">
                <FaCloudUploadAlt className="text-gray-800 text-6xl mb-4" />
                <p className="text-gray-800">Drop image here,</p>
                <label
                  htmlFor="file-upload"
                  className="cursor-pointer text-blue-500 hover:underline"
                >
                  Select from library
                </label>
                <input
                  id="file-upload"
                  type="file"
                  className="hidden"
                  onChange={(e) => handleImageChange(e, index)}
                />
              </div>
            )}
            <div className="py-3 flex gap-10">
              <div className="flex w-1/2 justify-between">
                <InputLabel label={"Order"} />
                <div className="w-1/2">
                  <InputNumber
                    value={bannerData.items[index].order_id}
                    onChange={(e) =>
                      setBannerData((prev) => {
                        const updatedItems = [...prev.items];
                        updatedItems[index] = {
                          ...updatedItems[index],
                          order_id: e,
                        };
                        return { ...prev, items: updatedItems };
                      })
                    }
                  />
                </div>
              </div>
              <div className="flex w-1/2 justify-between">
                <InputLabel label={"Filter"} />
                <div className="w-1/2">
                  <InputField
                    value={bannerData.items[index].filter}
                    onChange={(e) =>
                      setBannerData((prev) => {
                        const updatedItems = [...prev.items];
                        updatedItems[index] = {
                          ...updatedItems[index],
                          filter: e.target.value,
                        };
                        return { ...prev, items: updatedItems };
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end gap-3 py-3">
              <button className="flex items-center gap-3 py-1 px-3 bg-red-500 rounded text-white">
                <MdDeleteSweep />
                Delete
              </button>
              <button className="bg-blue-500 px-4 rounded text-white" onClick={()=>handleSubmit(item)}>
                Submit
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BannersSingle;
